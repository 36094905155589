import { useEffect, useState } from "react";
import clientesRepository from "../repositories/clientesRepository";

export default () => {
    const [clientes, setClientes] = useState([]);
  
    useEffect(() => {
      ;(async () => {
        const response = await clientesRepository.list({
          paginate: { [`$limit`]: 999 },
        });
  
        if (response.ok) {
          setClientes(response.data.map(cli => ({
            label: cli.nome,
            value: cli.id
          })))
        }
      })()
  
    }, []);

    return {
      options: clientes
    }

}