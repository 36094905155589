import { restApi } from '../../services/api'
import {createFormDataRepository as createRepository} from '../../Components/Repository'
import { query, paginate } from '../../lib/feathers'
import { handleFileField } from './repositoryUtils';

const engenheirosRepository = createRepository({
  path: 'engenheiros',
  restApi,
  queryTransform: {
    nome: query.ilike('nome'),
    ...paginate
  },
  dataTransform: (data) => {
    handleFileField(data, 'art', 'artUrl');

    return data;
  }
})

export default engenheirosRepository
