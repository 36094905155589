import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Field } from 'react-final-form'
import { Autocomplete, Select, TextField } from '../../Components/Fields'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import { ROLES } from './UsersList'
import { Email } from '../../Components/Fields'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'
import empresasRepository from '../repositories/empresasRepository'
import usersRepository from '../repositories/usersRepository'

const UserForm = props => {
  
  const [empresas, setEmpresas] = useState();

  useEffect(() => {
    ;(async () => {
      const response = await empresasRepository.list({
        paginate: { [`$limit`]: 999 },
      });

      if (response.ok) {
        setEmpresas(response.data.map(cli => ({
          label: cli.nome,
          value: cli.id
        })))
      }
    })()

  }, []);

  const entityManager = useEntityManager({
    repository: usersRepository,
    handleError: data => data.message,
    goBackAfterSave: true
  })

  const userSchema = yup.object().shape({
    email: yup.string().email('Email Inválido').required('Obrigatório'),
    role: yup.string().required('Obrigatório'),
    empresaId: yup.number().required('Obrigatório'),
    password: entityManager.isNew ? yup.string().required('Obrigatório') : yup.string().notRequired(),
  })
  
  const validate = yupValidation(userSchema);

  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        validate={validate}
        withPaper
      >
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Email />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              fullWidth
              name="role"
              label="Papel"
              component={Select}
              options={ROLES}
              hideEmpty
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              fullWidth
              name="empresaId"
              label="Empresa"
              component={Autocomplete}
              options={empresas}
            />
          </Grid>
          {entityManager.isNew && (
            <Grid item sm={6} xs={12}>
              <Field
                fullWidth
                name="password"
                label="Senha"
                type="password"
                component={TextField}
              />
            </Grid>
          )}
        </Grid>
      </CrudForm>
    </>
  )
}

export default UserForm
