import { restApi } from '../../services/api'
import {createBasicRepository as createRepository} from '../../Components/Repository'
import { query, paginate } from '../../lib/feathers'

const usersRepository = createRepository({
  path: 'users',
  restApi,
  queryTransform: {
    name: query.ilike('name'),
    email: query.ilike('email'),
    ...paginate
  },
})

export default usersRepository
