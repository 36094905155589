import { restApi } from '../../services/api'
import {createFormDataRepository as createRepository} from '../../Components/Repository'
import { query, paginate } from '../../lib/feathers'
import { handleFileField } from './repositoryUtils'

const clientesRepository = createRepository({
  path: 'clientes',
  restApi,
  queryTransform: {
    nome: query.ilike('nome'),
    cnpj: value => ['cnpj', value.replace(/\D/g,"")],
    ...paginate
  },
  dataTransform: (data) => {
    handleFileField(data, 'logo', 'logoUrl');
    handleFileField(data, 'documentoBase', 'documentoBaseUrl');
    
    return data;
  }
})

export default clientesRepository
