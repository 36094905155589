import React from 'react'
import { Grid } from '@material-ui/core'
import ReadInput from '../../Components/Common/ReadInput';

const DadosIdentificacao = ({ item, classes }) => {
  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <ReadInput 
        label="Atividade do Equipamento"
        value={item.atividade}
      />
    </Grid>
    <Grid item xs={12}>
      <ReadInput 
        label="Localização/Setor"
        value={item.localizacao}
      />
    </Grid>
    <Grid item xs={12} sm={8}>
      <ReadInput 
        label="Entrada / Início"
        value={item.entrada}
      />
    </Grid>          
    <Grid item xs={12} sm={4}>
      <ReadInput 
        label="Modo Entrada"
        value={item.modoEntrada}
      />
    </Grid>
    <Grid item xs={12} sm={8}>
      <ReadInput 
        label="Saída / Término"
        value={item.saida}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <ReadInput 
        label="Modo Saída"
        value={item.modoSaida}
      />
    </Grid>
    {item.maquinas.map(maq => <Grid item xs={12} key={maq.id}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReadInput 
            label="Máquina"
            value={maq.nome}
          />
        </Grid>
      </Grid>
    </Grid>)}
  </Grid>
}

export default DadosIdentificacao;