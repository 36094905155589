import React, { createRef, useEffect, useState } from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core'
import AddImageInput from '../../Components/Common/AddImageInput'
import noImage from '../../img/no_logo.png'
import fotosConformidadeRepository from '../repositories/fotosConformidadeRepository'
import RemovableImage from '../../Components/Common/RemovableImage'
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import ImageEditor from '@toast-ui/react-image-editor';
import { dataURLtoBlob } from '../../utils/utils'

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    paddingTop: theme.spacing(2)
  },
  imageEditorContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2)
  },
  imageEditorHidden: {
    visibility: 'hidden',
    height: 0
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  },
  imageContainer: {
    padding: theme.spacing(1)
  }
}))

const FotosConformidade = ({ conformidadePerigoId, setMessage }) => {
  
  const [fotosConformidade, setFotosConformidade] = useState([]);
  const [version, setVersion] = useState(0);
  const [fileEdit, setFileEdit] = useState();
  const editorRef = createRef();

  useEffect(() => {
    ;(async () => {
      const response = await fotosConformidadeRepository.list({ 
        query: {
          conformidadePerigoId
        }
      });

      if (response.ok) {
        setFotosConformidade(response.data)
      }
    })()

  }, [conformidadePerigoId, version]);

  const onClickDelete = (id) => {
    fotosConformidadeRepository.remove(id).then(response => {
      if (response.ok) {
        setVersion(version + 1)
      } else {
        setMessage("Erro ao remover imagem!")
      }
    })
  }

  const onAddFoto = () => {
    const editorInstance = editorRef.current.getInstance();
    dataURLtoBlob(editorInstance.toDataURL(), fileEdit.name, fileEdit.type).then(file => {
      fotosConformidadeRepository.create({ foto: file, conformidadePerigoId }).then(response => {
        if (response.ok) {
          setVersion(version + 1);
          setFileEdit(undefined);
        } else {
          setMessage("Erro ao adicionar imagem!")
        }
      })
    });
  }

  const onLoadFoto = (data) => {
    const editorInstance = editorRef.current.getInstance();
    if (data && data.length > 0) {
      const file = data[0].file;
      editorInstance.loadImageFromFile(file).then(result => {
        editorInstance.ui.activeMenuEvent()
        setFileEdit(file);
      })
    }
  }

  const classes = useStyles();

  const myTheme = {
    "header.display": "none"
  };

  return <Grid container>
    <Grid item xs={12}>
      <span className={classes.label}>{"Fotos"}</span>
    </Grid>
    {fotosConformidade.map(f => <Grid item key={f.id} className={classes.imageContainer}>
      <RemovableImage size={200} src={f.fotoUrl} onClickDelete={() => onClickDelete(f)} />
    </Grid>)}
    <Grid item>
      <AddImageInput onChange={onLoadFoto} size={200} backgroundImage={noImage} />
    </Grid>
    <Grid item xs={12} className={fileEdit ? classes.imageEditorContainer : classes.imageEditorHidden}>
      <ImageEditor
        ref={editorRef}
        includeUI={{
          theme: myTheme,
          menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'text', 'mask', 'filter'],
          initMenu: 'icon',
          uiSize: {
            width: '1000px',
            height: '800px',
          },
          menuBarPosition: 'bottom',
        }}
        cssMaxHeight={500}
        cssMaxWidth={700}
        usageStatistics={false}
      />
    </Grid>
    
    {fileEdit && <Grid item xs={12} className={classes.buttonContainer}>
      <Button variant="outlined" onClick={onAddFoto}>
        {"Adicionar Foto"}
      </Button>
    </Grid>}
  </Grid>
}

export default FotosConformidade;