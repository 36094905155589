import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, Collapse, Checkbox, Button } from '@material-ui/core'
import Fetching from '../../Components/Common/Fetching'
import { restApi } from '../../services/api'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(3)
  },
  titleBox: {
    borderBottom: `solid 1px ${theme.palette.secondary.main}`,
    marginBottom: theme.spacing(2)
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(0.5)
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  messageContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  messageText: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    textAlign: 'center'
  },
  equipamentosText: {
    fontSize: 12,
    color: theme.palette.secondary.main,
    paddingRight: theme.spacing(2)
  },
  downloadButton: {
    marginTop: theme.spacing(2),
    width: 200,
    display: 'flex',
    justifyContent: 'space-around'
  },
  link: {
    marginLeft: 4,
    fontSize: 14,
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
}))

const BookSafety = ({ clienteId, setError, documentoBase }) => {

  const [equipamentosIn, setEquipamentosIn] = useState(true);
  const [equipamentos, setEquipamentos] = useState([]);
  const [fetchingEquipamentos, setFetchingEquipamentos] = useState(false);
  const [deselecionados, setDeselecionados] = useState([]);
  const [fetchingBookSafety, setFetchingBookSafety] = useState(false);
  const [bookSafetySolicitado, setBookSafetySolicitado] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    ;(async () => {
      setFetchingEquipamentos(true);
      const response = await restApi.get(`equipamentos?clienteId=${clienteId}&$limit=999&$sort[codigo]=1`)

      if (response.ok) {
        const equipamentosResponse = response.data.data;
        setEquipamentos(equipamentosResponse);
      }

      setFetchingEquipamentos(false);

    })()

  }, [clienteId]);

  const gerarBookSafety = async () => {
    setFetchingBookSafety(true);
    const response = await restApi.post(`book-safety`, {
      clienteId,
      equipamentos: equipamentos.map(e => e.id).filter(id => !deselecionados.includes(id))
    });

    if (!response.ok) {
      setError("Erro ao gerar relatório");
    }
    
    setFetchingBookSafety(false);
    setBookSafetySolicitado(true);
    
  }
    
  return <Grid container className={classes.container}>
    <Grid item xs={12} className={classes.titleBox}>
      <div onClick={() => setEquipamentosIn(!equipamentosIn)}
        className={classes.title}
      >
        {`BOOK SAFETY ${equipamentosIn ? '<<' : '>>'}`}
      </div>
    </Grid>
    
    <Grid item xs={12}>
      <Collapse in={equipamentosIn}>
        <Fetching isFetching={fetchingEquipamentos}>
          <Grid container className={classes.center}>
            {equipamentos.length === 0 && <Grid item xs={12} className={classes.messageContainer}>
              <span className={classes.messageText}>{"Gere aqui o Book Safety do cliente após adicionar os equipamentos"}</span>
            </Grid>}

            {equipamentos.length > 0 && <>
              {documentoBase && <Grid item xs={12} className={classes.messageContainer}>
                <div className={classes.messageText}>
                  {"Este Book Safety será gerado com o Documento Base inserido acima."}
                  <br/>
                  {"Remova o Documento Base acima se desejar utilizar o Documento Base padrão do sistema."}
                </div>
              </Grid>}
              {!documentoBase && <Grid item xs={12} className={classes.messageContainer}>
                <div className={classes.messageText}>
                  {"Este Book Safety será gerado com o Documento Base padrão do sistema."}
                  <br/>
                  {"Insira um Documento Base acima se desejar utilizar um Documento Base específico para este cliente."}
                </div>
              </Grid>}
              {equipamentos.map(equipamento => <Grid item xs={12} key={equipamento.id}>
                <Checkbox
                  size="small"
                  checked={!deselecionados.includes(equipamento.id)} 
                  onChange={(event) => {
                    if (event.target.checked) {
                      setDeselecionados(deselecionados.filter(des => des !== equipamento.id))
                    } else {
                      setDeselecionados([equipamento.id, ...deselecionados])
                    }
                  }}
                />
                <span className={classes.equipamentosText}>{`${equipamento.codigo || 'Sem código'} - ${equipamento.nome}`}</span>
              </Grid>)}
              {!bookSafetySolicitado && <Grid item xs={12} className={classes.center}>
                <Button variant="outlined" color="secondary" onClick={gerarBookSafety} className={classes.downloadButton}>
                  <Fetching isFetching={fetchingBookSafety} size={24}>{"Gerar Book Safety"}</Fetching>
                </Button>
              </Grid>}
              {bookSafetySolicitado && <Grid item xs={12} className={classes.messageContainer}>
                <div className={classes.messageText}>
                  {"A geração do Book Safety foi iniciada e pode demorar vários minutos, de acordo com a quantidade de equipamentos."}
                  <br/>
                  {"Veja o status da geração no menu "}
                  <span className={classes.link} onClick={() => history.push('/book-safety')}>{"Books Gerados"}</span>
                </div>
              </Grid>}
            </>}
          </Grid>
        </Fetching>
      </Collapse>
    </Grid>
    
  </Grid>
}

export default BookSafety;