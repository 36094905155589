import React, { useState } from 'react'
import { Filter, List, Snackbar } from '../../Components/Common'
import { useFilter, useListRepository } from '../../Components/Hooks'
import { Autocomplete, TextField } from '../../Components/Fields'
import { Field } from 'react-final-form'
import { Grid, Button, makeStyles } from '@material-ui/core'
import equipamentosRepository from '../repositories/equipamentosRepository'
import copyEquipamentoRepository from '../repositories/copyEquipamentoRepository'
import useClientesOptions from '../clientes/useClientesOptions'
import moment from 'moment'
import { useHistory } from 'react-router'

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
}))

const EquipamentosFilter = () => {
  const filter = useFilter({});

  const clientesOptions = useClientesOptions();

  return (
    <Filter {...filter} labels={{ find: 'Buscar', clear: 'Limpar' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Field
            fullWidth
            name="nome"
            label="Nome"
            component={TextField}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            fullWidth
            name="clienteId"
            label="Cliente"
            component={Autocomplete}
            {...clientesOptions}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

const EquipamentosList = ({ ...props }) => {

  const [message, setMessage] = useState('');
  const history = useHistory();

  const onClickCopiar = async (id) => {
    const response = await copyEquipamentoRepository.create({ id });
    if (!response.ok) {
      setMessage('Erro ao copiar equipamento!');
    } else {
      history.push(`/equipamentos/${response.data.id}`)
    }  
  }
  
  const ButtonCopiar = ({ id }) => {
    const classes = useStyles();
  
    return <div className={classes.buttonContainer}>
      <Button variant="outlined" color="secondary" onClick={async () => onClickCopiar(id) }>
        {"COPIAR"}
      </Button>
    </div>
  }

  const listOptions = {
    fields: {
      codigo: {
        label: 'Código',
      },
      createdAt: {
        label: 'Data de Inclusão',
        format: (value) => moment(value).format('DD/MM/YYYY')
      },
      nome: {
        label: 'Nome',
      },
      cliente: {
        label: 'Cliente',
        format: (cliente) => cliente.nome
      },
      id: {
        label: '',
        format: (id) => <ButtonCopiar id={id} />
      }
    }
  }

  const listHook = useListRepository({
    repository: equipamentosRepository,
    defaultSort: 'codigo asc',
    path: 'equipamentos'
  })
  
  return (
    <>
      <EquipamentosFilter />
      <List
        {...props}
        {...listHook}
        removeItem={listHook.removeItem}
        onClickEdit={listHook.onClickEdit}
        onClickRow={listHook.onClickEdit}
        onClickNew={listHook.onClickNew}
        onClickView={null}
        hideDownloadIcon
        listOptions={listOptions}
      />
      <Snackbar
        message={message}
        onClose={() => setMessage('')}
      />
    </>
  )
}

export default EquipamentosList
