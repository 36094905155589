import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Field } from 'react-final-form'
import { TextField, Select } from '../../Components/Fields'
import { CrudForm, ImagePicker } from '../../Components/Common'
import configuracoesRepository from '../repositories/configuracoesRepository'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'
import CNPJField from '../../Components/Fields/CNPJField'
import withoutLogo from '../../img/no_logo.png'
import { makeStyles } from '@material-ui/core'
import estadosOptions from '../engenheiros/estadosOptions'

const useStyles = makeStyles(theme => ({
  filesContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}))

const schema = yup.object().shape({
  nome: yup.string().required('Obrigatório'),
})

const validate = yupValidation(schema)

const ConfiguracoesForm = props => {

  const [configuracoes, setConfiguracoes] = useState({});  
  const [message, setMessage] = useState('')

  useEffect(() => {
    ;(async () => {
      const response = await configuracoesRepository.list();

      if (response.ok) {
        setConfiguracoes(response.data)
      }
    })()

  }, []);

  const onSubmit = async (data) => {
    const response = await configuracoesRepository.create(data);
    if (response.ok) {
      setConfiguracoes(response.data);
      setMessage("Configurações salvas!");
    } else {
      setMessage("Erro ao salvar as configurações!")
    }

    return response;
  }

  const classes = useStyles();

  return (
    <>
      <CrudForm
        {...props}
        validate={validate}
        withPaper
        item={configuracoes}
        onSubmit={onSubmit}
        message={message}
        clearMessage={() => setMessage('')}
        hideGoBack
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="nome"
                  label="Nome"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="cnpj"
                  label="CNPJ"
                  component={CNPJField}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.filesContainer}>
            <Field
              name="logoUrl"
              defaultImage={withoutLogo}
              size={120}
              component={ImagePicker}
              label="Logo"
            />
            <Field
              name="marcaDaguaUrl"
              defaultImage={withoutLogo}
              size={120}
              component={ImagePicker}
              label="Marca D'água"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="observacoes"
              label="Observações no PDF"
              component={TextField}
              multiline
            />
          </Grid> 
          <Grid item xs={12}>
            <Field
              fullWidth
              name="conclusaoConforme"
              label="Texto conclusão quando conforme"
              component={TextField}
              multiline
            />
          </Grid> 
          <Grid item xs={12}>
            <Field
              fullWidth
              name="conclusaoParcialmenteConforme"
              label="Texto conclusão quando parcialmente conforme"
              component={TextField}
              multiline
            />
          </Grid> 
          <Grid item xs={12}>
            <Field
              fullWidth
              name="conclusaoNaoConforme"
              label="Texto conclusão quando não conforme"
              component={TextField}
              multiline
            />
          </Grid> 
          <Grid item xs={12} sm={6}>
            <Field
              fullWidth
              name="cidade"
              label="Cidade"
              component={TextField}
            />
          </Grid>
          <Grid item xs={12} sm={3} >
            <Field
              fullWidth
              name="estado"
              component={Select}
              label="Estado"
              options={estadosOptions}
            />
          </Grid>
          <Grid item xs={12} sm={3} >
            <Field
              fullWidth
              name="numeroCrea"
              component={TextField}
              label="Número CREA"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="localizacaoCompleta"
              label="Localização Completa (Book Safety)"
              component={TextField}
            />
          </Grid> 
        </Grid>
      </CrudForm>
    </>
  )
}

export default ConfiguracoesForm
