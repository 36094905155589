import React, { useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./Theme";
import {
  VerifyLogin } from "./modules/login";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import api from "./services/api";
import usersRepository from "./modules/repositories/usersRepository";
import { AuthorizationContext } from "./modules/users/abilities";
import { Ability } from "@casl/ability";
import { withStyles } from "@material-ui/core";
import { Auth as AuthProvider } from "./Components/Login"
import AppGlobalRouter from "./Router/AppGlobalRouter";

const styles = () => ({
  "@global": {
    "*::-webkit-scrollbar": {
      height: "0.6em",
      width: "0.6em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
});

const App = () => {
  const [ability, setAbility] = useState();

  const updateUser = async (id) => {
    const response = await usersRepository.getOne({ id });
    if (response.ok) {
      setAbility(new Ability(response.data.abilities));
    }
  };

  const withUser = async user => {
    await api.setToken(user.token);
    updateUser(user.id);
  };

  return (
    <ThemeProvider theme={theme}>
      <AuthorizationContext.Provider value={ability}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthProvider
            withUser={withUser}
            onLogout={() => {
              setAbility(null);
            }}
          >
            <VerifyLogin />
            <AppGlobalRouter/>
          </AuthProvider>
        </MuiPickersUtilsProvider>
      </AuthorizationContext.Provider>
    </ThemeProvider>
  );
};

export default withStyles(styles)(App);
