import React from 'react'
import { Grid } from '@material-ui/core'
import ReadInput from '../../Components/Common/ReadInput'

const DadosEnergia = ({ item }) => {

  return <Grid container spacing={2}>
    <Grid item xs={12} sm={6} md={3}>
      <ReadInput
        value={item.tipoEnergia?.nome}
        label="Tipo"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <ReadInput
        value={item.tipoAplicacaoEnergia?.descricao}
        label="Aplicação"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <ReadInput
        value={item.modoAlimentacaoEnergia?.descricao}
        label="Modo Alimentação"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <ReadInput
        value={item.intensidade}
        label="Intensidade (Volts)"
      />
    </Grid>
    <Grid item xs={12}>
      <ReadInput
        value={item.observacoesEnergia}
        label="Observações"
      />
    </Grid>
  </Grid>
}

export default DadosEnergia;