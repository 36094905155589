import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Field } from 'react-final-form'
import { TextField } from '../../Components/Fields'
import { CrudForm, ImagePicker } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'
import { validateCNPJ } from "validations-br";
import CNPJField from '../../Components/Fields/CNPJField'
import withoutLogo from '../../img/no_logo.png'
import { makeStyles } from '@material-ui/core'
import brasilApi from '../../services/brasilApi'
import Fetching from '../../Components/Common/Fetching'
import { setValue } from '../../utils/utils'
import clientesRepository from '../repositories/clientesRepository'
import BookSafety from './BookSafety'
import FilePicker from '../../Components/Common/FilePicker'

const useStyles = makeStyles(theme => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
}))

const schema = yup.object().shape({
  cnpj: yup.string().required('Obrigatório').test("is-cnpj", "CNPJ inválido", (value) => validateCNPJ(value)),
  nome: yup.string().required('Obrigatório'),
})

const validate = yupValidation(schema)

const ClienteForm = props => {

  const entityManager = useEntityManager({
    repository: clientesRepository,
    handleError: data => data.message
  });  


  const [fetchingCNPJ, setFetchingCNPJ] = useState(false);

  const onChangeCnpj = (cnpj, form) => {
    if (cnpj) {
      const numeroCnpj = cnpj.replace(/\D/g,'');
      if (numeroCnpj.length === 14) {
        setFetchingCNPJ(true);
        brasilApi.getCnpj(numeroCnpj).then(response => {
          if (response.ok) {
            const { data } = response;
            form.mutators.setValue("cnpj", cnpj);
            form.mutators.setValue("nome", data.razao_social);
            form.mutators.setValue("endereco", `${data.descricao_tipo_logradouro || ''} ${data.logradouro || ''}, ${data.numero || ''}, ${data.complemento || ''}, ${data.bairro || ''}, ${data.municipio || ''}, ${data.uf || ''}`);
          }
          form.mutators.setValue("cnpj", cnpj);
          setFetchingCNPJ(false);
        });
      }    
    }
  }

  const classes = useStyles();

  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        validate={validate}
        withPaper
        formProps={{
          mutators: { setValue }
        }}
      >
        {({ form, values }) => <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={2} xl={1} className={classes.logoContainer} >
            <Field
              name="logoUrl"
              defaultImage={withoutLogo}
              size={100}
              component={ImagePicker}
              label="Logo"
            />
          </Grid>
          <Grid item xs={12} sm={9} md={10} xl={11} >
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Field
                  fullWidth
                  name="cnpj"
                  label="CNPJ"
                  component={CNPJField}
                  customOnChange={(event) => onChangeCnpj(event.target.value, form)}
                />
              </Grid>
              <Fetching isFetching={fetchingCNPJ}>
                <>
                  <Grid item sm={6} xs={12}>
                    <Field
                      fullWidth
                      name="nome"
                      label="Nome"
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name="endereco"
                      label="Endereço"
                      component={TextField}
                    />
                  </Grid>
                </>
              </Fetching>
            </Grid>
          </Grid>
          <Grid item xs={12}>            
            <Field
              name="documentoBaseUrl"
              component={FilePicker}
              label="Documento Base"
              accept='.pdf'
              filename="documento_base"
            />
          </Grid>

          <Grid item xs={12}>
            {!entityManager.isNew && !form.getState().dirty && <BookSafety 
              clienteId={entityManager.id} 
              setError={entityManager.setError}
              documentoBase={values.documentoBaseUrl}
            />}
          </Grid>
        </Grid>}
      </CrudForm>
    </>
  )
}

export default ClienteForm
