import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import ReadInput from '../../Components/Common/ReadInput';
import moment from 'moment'

const useStyles = makeStyles(theme => ({  
  container: {
    padding: theme.spacing(2)
  },
  label: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  materiais: {
    marginTop: theme.spacing(2)
  },
  fotosContainer: {
    display: 'flex'
  },
  fotoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  foto: {
    width: 150,
    height: 150,
    objectFit: 'cover'
  },
  obsFoto: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    fontStyle: 'italic'
  }
}));

const PlanoAcaoView = ({ item }) => {

  const classes = useStyles();

  return <Grid container spacing={2} className={classes.container}>
    <Grid item xs={12}>
      <ReadInput
        value={item.itemNorma ? `${item.itemNorma.indice} - ${item.itemNorma.descricao}` : ''}
        label="Item da Norma"
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <ReadInput
        value={item.responsavel}
        label="Responsável"
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <ReadInput
        label="Data Prevista"
        value={item.dataPrevista ? moment(item.dataPrevista).format('DD/MM/YYYY') : ''}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <ReadInput
        label="Data Realizada"
        value={item.dataRealizada ? moment(item.dataRealizada).format('DD/MM/YYYY') : ''}
      />
    </Grid> 
    {item.materiais.length > 0 && <Grid item xs={12} className={classes.materiais}>
      <span className={classes.label}>{"Materiais Necessários"}</span>
    </Grid>}
    {item.materiais.map(mpa => <Grid item xs={12} key={mpa.id}>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <ReadInput
            label="Material"
            value={mpa.material.descricao}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ReadInput
            label="Quantidade"
            value={mpa.material.quantidade}
          />
        </Grid>
      </Grid>
    </Grid>)}
    {item.fotos.length > 0 && <>
      <Grid item xs={12} className={classes.fotosContainer}>
        {item.fotos.map(f => <div key={f.id} className={classes.fotoContainer}>        
          <img
            className={classes.foto}
            src={f.data}
            alt="Foto do Plano de Ação"
          />
        </div>)}
      </Grid>
      <Grid item xs={12}>
        <span className={classes.obsFoto}>{"*Foi retirada a visibilidade das fotos para preservar a privacidade da empresa."}</span>
      </Grid>
    </>}
  </Grid>
}

export default PlanoAcaoView
