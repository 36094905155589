import { restApi } from '../../services/api'
import {createFormDataRepository as createRepository} from '../../Components/Repository'
import { query, paginate } from '../../lib/feathers'
import { handleFileField } from './repositoryUtils';

const equipamentosRepository = createRepository({
  path: 'equipamentos',
  restApi,
  queryTransform: {
    nome: query.ilike('nome'),
    ...paginate
  },
  dataTransform: (data) => {
    handleFileField(data, 'foto', 'fotoUrl');
    handleFileField(data, 'plantaBaixa', 'plantaBaixaUrl');

    for (const maq of data.maquinas) {
      if (maq.id && !maq.op) {
        maq.op = 'replace'
      }
    }

    return data;
  }
})

export default equipamentosRepository
