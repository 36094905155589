import { restApi } from '../../services/api'
import {createBasicRepository as createRepository} from '../../Components/Repository'
import { query, paginate } from '../../lib/feathers'

const equipamentosCompartilhadosRepository = createRepository({
  path: 'equipamentos-compartilhados',
  restApi,
  queryTransform: {
    nome: query.ilike('nome'),
    ...paginate
  }
})

export default equipamentosCompartilhadosRepository
