import React from 'react'
import { List } from '../../Components/Common'
import { useListRepository } from '../../Components/Hooks'
import usersRepository from '../repositories/usersRepository'

export const ROLES = [
  { label: 'Administrador', value: 'ADMIN' },
  { label: 'Usuário', value: 'USER' },
]

const formatRole = value => {
  const role = ROLES.find(r => r.value === value)
  return role ? role.label : ''
}

const UsersList = ({ ...props }) => {
  const listOptions = {
    defaultOrder: 'email',
    fields: {
      email: {
        label: 'E-mail'
      },
      role: {
        label: 'Papel',
        format: role => formatRole(role)
      },
    }
  }

  const listHook = useListRepository({
    repository: usersRepository,
    defaultSort: 'email',
    path: 'users'
  })
  
  return (
    <List
      {...props}
      {...listHook}
      removeItem={listHook.removeItem}
      onClickEdit={listHook.onClickEdit}
      onClickNew={listHook.onClickNew}
      onClickView={null}
      hideDownloadIcon
      listOptions={listOptions}
    />
  )
}

export default UsersList
