import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Field } from 'react-final-form'
import { TextField, Select } from '../../Components/Fields'
import tiposEnergiaRepository from '../repositories/tiposEnergiaRepository'

const DadosEnergia = () => {
  
  const [tiposEnergia, setTiposEnergia] = useState([]);
  const [tipoEnergiaSelecionado, setTipoEnergiaSelecionado] = useState();

  useEffect(() => {
    ;(async () => {
      const response = await tiposEnergiaRepository.list({
        paginate: { [`$limit`]: 999 },
      });

      if (response.ok) {
        setTiposEnergia(response.data.map(te => ({
          label: te.nome,
          value: te.id,
          tiposAplicacao: te.tiposAplicacao,
          modosAlimentacao: te.modosAlimentacao
        })))
      }
    })()

  }, []);

  const getTiposAplicacao = () => {
    if (tiposEnergia.length === 0) {
      return []
    }

    if (!tipoEnergiaSelecionado) {
      const tiposAplicacao = [];
      tiposEnergia.forEach(te => {
        te.tiposAplicacao.forEach(ta => tiposAplicacao.push({
          label: ta.descricao,
          value: ta.id
        }))
      })

      return tiposAplicacao;
    }

    return tiposEnergia
      .find(te => te.value === tipoEnergiaSelecionado)
      .tiposAplicacao.map(ta => ({
        label: ta.descricao,
        value: ta.id
      }))
  }

  const getModosAlimentacao = () => {
    if (tiposEnergia.length === 0) {
      return []
    }

    if (!tipoEnergiaSelecionado) {
      const modosAlimentacao = [];
      tiposEnergia.forEach(te => {
        te.modosAlimentacao.forEach(ta => modosAlimentacao.push({
          label: ta.descricao,
          value: ta.id
        }))
      })

      return modosAlimentacao;
    }

    return tiposEnergia
      .find(te => te.value === tipoEnergiaSelecionado)
      .modosAlimentacao.map(ma => ({
        label: ma.descricao,
        value: ma.id
      }))
  }

  return <Grid container spacing={2}>
    <Grid item xs={12} sm={6} md={3}>
      <Field
        fullWidth
        name="tipoEnergiaId"
        label="Tipo"
        component={Select}
        options={tiposEnergia}
        onChange={(event) => setTipoEnergiaSelecionado(event.target.value)}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Field
        fullWidth
        name="tipoAplicacaoEnergiaId"
        label="Aplicação"
        component={Select}
        options={getTiposAplicacao()}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Field
        fullWidth
        name="modoAlimentacaoEnergiaId"
        label="Modo Alimentação"
        component={Select}
        options={getModosAlimentacao()}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Field
        fullWidth
        name="intensidade"
        label="Intensidade (Volts)"
        component={TextField}
        type="number"
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        fullWidth
        name="observacoesEnergia"
        label="Observações"
        component={TextField}
      />
    </Grid>
  </Grid>
}

export default DadosEnergia;