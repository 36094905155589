import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#001777',
    },
    secondary: {
      main: '#7e81a4', //Fundo Menu
    },
    grey: {
      200: '#f0f0fa', //Fundo
      500: '#f0f0fa', //Texto menu
      800: '#7e81a4', //Texto Nome usuário
    },
    common: {
      grey: '#7e81a4', //Título da Página
    },
    text: {
      primary: '#000000',
      secondary: '#303030',
    },
  },
})
