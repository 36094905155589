import { restApi } from '../../services/api'
import {createFormDataRepository as createRepository} from '../../Components/Repository'

const fotosConformidadeRepository = createRepository({
  path: 'fotos-conformidade',
  restApi,
  listResponsePath: {
    pathData: ['data'],
    pathCount: ['total'],
  },
})

export default fotosConformidadeRepository
