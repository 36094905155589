import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
  iconButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.white,
    '&:hover': {
      background: theme.palette.primary.main,
    }
  },
  avatarControlsContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }
})

const RemoveControls = ({classes, id, onClickDelete, ...props}) => (
  <div
    {...props}
    className={classes.avatarControlsContainer}>
    <IconButton onClick={onClickDelete} className={classes.iconButton}>
      <DeleteIcon />
    </IconButton>
  </div>
)

function RemovableImage({classes, id='image-pick', src, onClickDelete, size}) {

  return (
    <div style={{position: 'relative', height: size, width: size}}>
      <img
        alt={id}
        src={src}
        style={{height: '100%', width: '100%', objectFit: "contain"}}
      />
        <RemoveControls
          onClick={onClickDelete}
          id={id}
          classes={classes}/>
    </div>
  )
}


export default withStyles(styles)(RemovableImage)
