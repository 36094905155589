import React from 'react'
import { List } from '../../Components/Common'
import { useListRepository } from '../../Components/Hooks'
import tiposEnergiaRepository from '../repositories/tiposEnergiaRepository'


const TiposEnergiaList = ({ ...props }) => {
  const listOptions = {
    defaultOrder: 'nome',
    fields: {
      nome: {
        label: 'Nome'
      },
    }
  }

  const listHook = useListRepository({
    repository: tiposEnergiaRepository,
    defaultSort: 'nome',
    path: 'tipos-energia'
  })
  
  return (
    <List
      {...props}
      {...listHook}
      removeItem={listHook.removeItem}
      onClickEdit={listHook.onClickEdit}
      onClickNew={listHook.onClickNew}
      onClickView={null}
      hideDownloadIcon
      listOptions={listOptions}
    />
  )
}

export default TiposEnergiaList
