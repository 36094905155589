import React from 'react'
import { Filter, List } from '../../Components/Common'
import { useFilter, useListRepository } from '../../Components/Hooks'
import { TextField } from '../../Components/Fields'
import { Field } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import planosAcaoRepository from '../repositories/planosAcaoRepository'

const PlanosAcaoFilter = () => {
  const filter = useFilter({})
  return (
    <Filter {...filter} labels={{ find: 'Buscar', clear: 'Limpar' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            fullWidth
            name="descricao"
            label="Descrição"
            component={TextField}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

const PlanosAcaoList = ({ ...props }) => {
  const listOptions = {
    defaultOrder: 'descricao',
    fields: {
      descricao: {
        label: 'Descrição',
      },
    }
  }

  const listHook = useListRepository({
    repository: planosAcaoRepository,
    defaultSort: 'descricao asc',
    path: 'planos-acao'
  })
  
  return (
    <>
      <PlanosAcaoFilter />
      <List
        {...props}
        {...listHook}
        removeItem={listHook.removeItem}
        onClickEdit={listHook.onClickEdit}
        onClickNew={listHook.onClickNew}
        onClickView={null}
        hideDownloadIcon
        listOptions={listOptions}
      />
    </>
  )
}

export default PlanosAcaoList
