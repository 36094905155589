import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Field } from 'react-final-form'
import { TextField, Select } from '../../Components/Fields'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'
import { makeStyles } from '@material-ui/core'
import engenheirosRepository from '../repositories/engenheirosRepository'
import estadosOptions from './estadosOptions'
import FilePicker from '../../Components/Common/FilePicker'

const useStyles = makeStyles(theme => ({
  fileContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
}))

const schema = yup.object().shape({
  nome: yup.string().required('Obrigatório'),
})

const validate = yupValidation(schema)

const EngenheiroForm = props => {

  const entityManager = useEntityManager({
    repository: engenheirosRepository,
    handleError: data => data.message,
    goBackAfterSave: true
  });

  const classes = useStyles();

  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        validate={validate}
        withPaper
      >
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Field
              fullWidth
              name="nome"
              component={TextField}
              label="Nome"
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <Field
              fullWidth
              name="funcao"
              component={TextField}
              label="Função"
            />
          </Grid>
          <Grid item xs={12} sm={3} >
            <Field
              fullWidth
              name="numeroCrea"
              component={TextField}
              label="Número CREA"
            />
          </Grid>
          <Grid item xs={12} sm={3} >
            <Field
              fullWidth
              name="estadoCrea"
              component={Select}
              label="Estado CREA"
              options={estadosOptions}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2} className={classes.fileContainer}>
            <Field
              name="artUrl"
              component={FilePicker}
              label="ART"
              accept='application/pdf'
              filename="art.pdf"
            />
          </Grid>                   
        </Grid>
      </CrudForm>
    </>
  )
}

export default EngenheiroForm
