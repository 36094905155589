import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Field } from 'react-final-form'
import { AutocompleteCreatable, Select } from '../../Components/Fields'
import conformidadesRepository from '../repositories/conformidadesRepository'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import conformidadesPerigoRepository from '../repositories/conformidadesPerigoRepository'
import { useHistory } from 'react-router'
import itensNormaRepository from '../repositories/itensNormaRepository'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'
import FotosConformidade from './FotosConformidade'

const useStyles = makeStyles(theme => ({
  mustSaveContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  mustSaveText: {
    fontSize: 14,
    color: theme.palette.secondary.main
  },
  mustSaveLink: {
    marginLeft: 4,
    fontSize: 14,
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
}))

const schema = yup.object().shape({
  conformidade: yup.string().required('Obrigatório'),
})

const validate = yupValidation(schema)

const ConformidadePerigoForm = (props) => {

  const classes = useStyles();

  const [message, setMessage] = useState();
  
  const [conformidades, setConformidades] = useState([]);
  const [itensNorma, setItensNorma] = useState([]);

  useEffect(() => {
    ;(async () => {
      const response = await conformidadesRepository.list({
        paginate: false,
        query: {
          $limit: 999
        }
      });

      if (response.ok) {
        setConformidades(response.data.map(pa => ({
          label: pa.descricao,
          value: pa.id
        })))
      }
    })()

  }, []);

  useEffect(() => {
    ;(async () => {
      const response = await itensNormaRepository.list();

      if (response.ok) {
        setItensNorma(response.data.map(it => ({
          label: `${it.indice} - ${it.descricao}`,
          value: it.id
        })))
      }
    })()

  }, []);

  const history = useHistory();
  
  const onGoBack = () => {
    let currentPathname = history.location.pathname
    history.push(currentPathname.substring(0, currentPathname.indexOf("conformidades") - 1));
  }

  const entityManager = useEntityManager({
    repository: conformidadesPerigoRepository,
    handleError: data => data.message,
    relationKey: 'perigoEquipamentoId',
    onGoBack
  });

  const loadOptions = (query) => {
    return Promise.resolve(conformidades
      .filter(pa => pa.label.toUpperCase().includes(query.toUpperCase())))
  }

  return <CrudForm
    {...props}
    {...entityManager}
    withPaper
    message={message}
    clearMessage={() => setMessage(undefined)}
    goBackOnSaveButton
    validate={validate}
  >
    {({ form }) => {
      return <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} >
              <Field
                fullWidth
                name="conformidade"
                label="Descrição da Conformidade"
                component={AutocompleteCreatable}
                loadOptions={loadOptions}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                name="itemNormaId"
                component={Select}
                label="Item da Norma"
                options={itensNorma}
              />
            </Grid>
          </Grid>
        </Grid>
        
        {entityManager.isNew && <Grid item xs={12} className={classes.mustSaveContainer}>
          <span className={classes.mustSaveText}>{"Para adicionar fotos, "}</span>
          <span className={classes.mustSaveLink} onClick={() => form.submit()}>{"salve as alterações"}</span>
        </Grid>}
        
        {!entityManager.isNew && <Grid item xs={12}>
          <FotosConformidade conformidadePerigoId={entityManager.childId} setMessage={setMessage} />
        </Grid>}
      </Grid>
    }}
  </CrudForm>
}

export default ConformidadePerigoForm;