import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Field } from 'react-final-form'
import { Autocomplete, TextField } from '../../Components/Fields'
import CNPJField from '../../Components/Fields/CNPJField'
import brasilApi from '../../services/brasilApi'
import Fetching from '../../Components/Common/Fetching'
import { restApi } from '../../services/api'

const useStyles = makeStyles(theme => ({
  linkGrid: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  linkText: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    fontSize: 12,
    cursor: 'pointer'
  }
}));

const DadosFabricante = ({ form }) => {

  const [fetchingCNPJ, setFetchingCNPJ] = useState(false);
  const [showBuscarFabricante, setShowBuscarFabricante] = useState(false);
  const [fabricantes, setFabricantes] = useState([]);

  useEffect(() => {
    ;(async () => {
      const response = await restApi.get('fabricantes');

      if (response.ok) {
        setFabricantes(response.data)
      }
    })()

  }, []);

  const onChangeCnpj = (cnpj, form) => {
    if (cnpj) {
      const numeroCnpj = cnpj.replace(/\D/g,'');
      if (numeroCnpj.length === 14) {
        setFetchingCNPJ(true);
        brasilApi.getCnpj(numeroCnpj).then(response => {
          if (response.ok) {
            const { data } = response;
            form.mutators.setValue("cnpjFabricante", cnpj);
            form.mutators.setValue("nomeFabricante", data.razao_social);
            form.mutators.setValue("contatoFabricante", data.ddd_telefone_1);
            form.mutators.setValue("enderecoFabricante", `${data.descricao_tipo_logradouro} ${data.logradouro}, ${data.numero}, ${data.complemento}, ${data.bairro}, ${data.municipio}, ${data.uf}`);
          }
          setFetchingCNPJ(false);
        }
        );
      }    
    } else {
      form.mutators.setValue("cnpjFabricante", undefined);
    }
  }

  const onSelectBusca = (item, action, form) => {
    if (action.action === 'select-option') {
      const fabricante = fabricantes.find(f => f.nomeFabricante === item.value)
      form.mutators.setValue("nomeFabricante", fabricante.nomeFabricante);
      form.mutators.setValue("cnpjFabricante", fabricante.cnpjFabricante);
      form.mutators.setValue("contatoFabricante", fabricante.contatoFabricante);
      form.mutators.setValue("enderecoFabricante", fabricante.enderecoFabricante);

      setShowBuscarFabricante(false);
    }

  }

  const classes = useStyles();
    
  return <Grid container spacing={2}>
    <Grid item xs={12} className={classes.linkGrid}>
      <span className={classes.linkText} onClick={() => setShowBuscarFabricante(!showBuscarFabricante)}>
        {showBuscarFabricante ? "Informar novo fabricante" : "Buscar fabricante já cadastrado"}
      </span>
    </Grid>

    {showBuscarFabricante && <Grid item xs={12}>
      <Field
        fullWidth
        name="nomeBusca"
        label="Nome do Fabricante"
        component={Autocomplete}
        options={fabricantes.map((f, i) => ({value: f.nomeFabricante, label: f.nomeFabricante}))}
        onChange={(item, action) => onSelectBusca(item, action, form)}
      />
    </Grid>}

    {!showBuscarFabricante && <>
      <Grid item xs={12} sm={4}>
        <Field
          fullWidth
          name="cnpjFabricante"
          label="CNPJ do Fabricante"
          component={CNPJField}        
          customOnChange={(event) => onChangeCnpj(event.target.value, form)}
        />
      </Grid>
      <Fetching isFetching={fetchingCNPJ}>
        <Grid item xs={12} sm={4}>
          <Field
            fullWidth
            name="nomeFabricante"
            label="Nome do Fabricante"
            component={TextField}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            fullWidth
            name="contatoFabricante"
            label="Contato do Fabricante"
            component={TextField}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            name="enderecoFabricante"
            label="Endereço do Fabricante"
            component={TextField}
          />
        </Grid>
      </Fetching>    
    </>}

    <Grid item xs={12} sm={4}>
      <Field
        fullWidth
        name="anoFabricacao"
        label="Ano de Fabricação"
        component={TextField}
        type="number"
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <Field
        fullWidth
        name="serie"
        label="Série"
        component={TextField}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <Field
        fullWidth
        name="modelo"
        label="Modelo"
        component={TextField}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Field
        fullWidth
        name="potencia"
        label="Potência"
        component={TextField}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Field
        fullWidth
        name="capacidade"
        label="Capacidade"
        component={TextField}
      />
    </Grid>
  </Grid>
}

export default DadosFabricante;