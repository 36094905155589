import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import withoutLogo from '../../img/no_logo.png'

const useStyles = makeStyles(theme => ({
  fieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
}))

const PlantaBaixa = ({ item }) => {

  const classes = useStyles();

  return <Grid container spacing={2}>
    <Grid item xs={12} className={classes.fieldContainer}>
      <img src={item.plantaBaixaUrl || withoutLogo}
        className={classes.image}
        alt="Planta Baixa"
      />
    </Grid>
    
  </Grid>
}

export default PlantaBaixa;