import React from 'react'
import {RecoverPassword} from '../../Components/Login'
import api from '../../services/api';
import logo from '../../img/logo.png'

export const handleSubmit = recoverPassword => async ({username}) => {
  const response = await recoverPassword({
    action: 'resetSend',
    email: username,
  });
  if (response.ok) {
    return Promise.resolve({ok: true, message: 'E-mail enviado, verifique sua caixa de entrada'})
  }
  return {ok: false, message: 'E-mail inválido'}
}

export default function CustomRecoverPassword({history}) {

  return (
    <RecoverPassword
    logo={
      <img
        alt='logo'
        src={logo}
        style={{ height: 280, marginBottom: 20 }} />
    }
      history={history}
      usernameLabel='E-mail'
      backLabel='Voltar'
      submitLabel='Recuperar'
      onBackClick={() => history.goBack()}
      onSubmit={handleSubmit(api.resetPassword)}
    />
  )
}


