import { restApi } from '../../services/api'
import {createFormDataRepository as createRepository} from '../../Components/Repository'
import { handleFileField } from './repositoryUtils';

const configuracoesRepository = createRepository({
  path: 'configuracoes',
  restApi,
  listResponsePath: {
    pathData: ['data'],
    pathCount: ['total'],
  },
  dataTransform: (data) => {
    handleFileField(data, 'logo', 'logoUrl');
    handleFileField(data, 'marcaDagua', 'marcaDaguaUrl');

    return data;
  }
})

export default configuracoesRepository
