import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Field } from 'react-final-form'
import { Select, TextField } from '../../Components/Fields'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import dispositivosSegurancaRepository from '../repositories/dispositivosSegurancaRepository'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'

const schema = yup.object().shape({
  descricao: yup.string().required('Obrigatório'),
  tipo: yup.string().required('Obrigatório'),
})

const validate = yupValidation(schema)

const DispositivosSegurancaForm = props => {

  const entityManager = useEntityManager({
    repository: dispositivosSegurancaRepository,
    handleError: data => data.message,
    goBackAfterSave: true
  });
  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        validate={validate}
        withPaper
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Field
              fullWidth
              name="descricao"
              label="Descrição"
              component={TextField}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field
              fullWidth
              name="tipo"
              label="Tipo"
              component={Select}
              options={[
                {
                  label: 'Proteçoes Fixas',
                  value: 'PROTECOES_FIXAS'
                }, 
                {
                  label: 'Proteções Móveis',
                  value: 'PROTECOES_MOVEIS'
                }, 
                {
                  label: 'Dispositivos Segurança',
                  value: 'DISPOSITIVOS_SEGURANCA'
                }, 
                {
                  label: 'Outros',
                  value: 'OUTROS'
                }
              ]}
            />
          </Grid>   
        </Grid>
      </CrudForm>
    </>
  )
}

export default DispositivosSegurancaForm
