import React from 'react'
import { Grid } from '@material-ui/core'
import { Field } from 'react-final-form'
import { TextField, Select } from '../../Components/Fields'

const simNaoOptions = [
  {
    label: 'SIM',
    value: true
  }, {
    label: 'NÃO',
    value: false
  }
]

const DadosEnergia = () => {

  return <Grid container spacing={2}>
    <Grid item xs={12} sm={4}>
      <Field
        fullWidth
        name="paradaMovimentos"
        label="Parada de Movimentos"
        component={Select}
        options={simNaoOptions}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <Field
        fullWidth
        name="tipoCiclo"
        label="Tipo de Ciclo"
        component={Select}
        options={[{
          value: 'CONSTANTE',
          label: 'CONSTANTE'
        },{
          value: 'INTERMITENTE',
          label: 'INTERMITENTE'
        },{
          value: 'EVENTUAL',
          label: 'EVENTUAL'
        },]}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <Field
        fullWidth
        name="intervaloCiclo"
        label="Intervalo do Ciclo (horas)"
        component={TextField}
        type="number"
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        fullWidth
        name="historicoAcidentes"
        label="Histórico de Acidentes"
        component={TextField}
      />
    </Grid>
    
    <Grid item xs={12} sm={3}>
      <Field
        fullWidth
        name="treinamentoOperacional"
        label="Treinamento Operacional"
        component={Select}
        options={simNaoOptions}
      />
    </Grid>
    <Grid item xs={12} sm={9}>
      <Field
        fullWidth
        name="obsTreinamentoOperacional"
        label="Obs. Treinamento Operacional"
        component={TextField}
      />
    </Grid>
    <Grid item xs={12} sm={3}>
      <Field
        fullWidth
        name="treinamentoSeguranca"
        label="Treinamento Segurança"
        component={Select}
        options={simNaoOptions}
      />
    </Grid>
    <Grid item xs={12} sm={9}>
      <Field
        fullWidth
        name="obsTreinamentoSeguranca"
        label="Obs. Treinamento Segurança"
        component={TextField}
      />
    </Grid>
    <Grid item xs={12} sm={3}>
      <Field
        fullWidth
        name="treinamentoMantenedores"
        label="Treinamento Mantenedores"
        component={Select}
        options={simNaoOptions}
      />
    </Grid>
    <Grid item xs={12} sm={9}>
      <Field
        fullWidth
        name="obsTreinamentoMantenedores"
        label="Obs. Treinamento Mantenedores"
        component={TextField}
      />
    </Grid>
    <Grid item xs={12} sm={3}>
      <Field
        fullWidth
        name="manualMaquina"
        label="Manual da Máquina"
        component={Select}
        options={simNaoOptions}
      />
    </Grid>
    <Grid item xs={12} sm={9}>
      <Field
        fullWidth
        name="obsManualMaquina"
        label="Obs. Manual da Máquina"
        component={TextField}
      />
    </Grid>
    <Grid item xs={12} sm={3}>
      <Field
        fullWidth
        name="procedimento"
        label="Procedimento"
        component={Select}
        options={simNaoOptions}
      />
    </Grid>
    <Grid item xs={12} sm={9}>
      <Field
        fullWidth
        name="obsProcedimento"
        label="Obs. Procedimento"
        component={TextField}
      />
    </Grid>
    
  </Grid>
}

export default DadosEnergia;