import { restApi } from '../../services/api'
import { createBasicRepository as createRepository } from '../../Components/Repository'
import { paginate, query } from '../../lib/feathers'

const planosAcaoRepository = createRepository({
  path: 'planos-acao',
  restApi,  
  queryTransform: {
    descricao: query.ilike('descricao'),
    ...paginate
  },
})

export default planosAcaoRepository
