import React from 'react'
import { Filter, List } from '../../Components/Common'
import { useFilter, useListRepository } from '../../Components/Hooks'
import { TextField } from '../../Components/Fields'
import { Field } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import engenheirosRepository from '../repositories/engenheirosRepository'

const EngenheirosFilter = () => {
  const filter = useFilter({})
  return (
    <Filter {...filter} labels={{ find: 'Buscar', clear: 'Limpar' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            fullWidth
            name="nome"
            label="Nome"
            component={TextField}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

const EngenheirosList = ({ ...props }) => {
  const listOptions = {
    defaultOrder: 'nome',
    fields: {
      nome: {
        label: 'Nome'
      },
      funcao: {
        label: 'Função'
      }
    }
  }

  const listHook = useListRepository({
    repository: engenheirosRepository,
    defaultSort: 'nome',
    path: 'engenheiros'
  })
  
  return (
    <>
      <EngenheirosFilter />
      <List
        {...props}
        {...listHook}
        removeItem={listHook.removeItem}
        onClickEdit={listHook.onClickEdit}
        onClickNew={listHook.onClickNew}
        onClickView={null}
        hideDownloadIcon
        listOptions={listOptions}
      />
    </>
  )
}

export default EngenheirosList
