import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Field } from 'react-final-form'
import { AutocompleteCreatable, TextField } from '../../Components/Fields'
import dispositivosSegurancaRepository from '../repositories/dispositivosSegurancaRepository'

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 12,
    color: theme.palette.secondary.main
  }
}))

const DadosSeguranca = () => {

  const classes = useStyles();
  
  const [dispositivosSeguranca, setDispositivosSeguranca] = useState([]);

  useEffect(() => {
    ;(async () => {
      const response = await dispositivosSegurancaRepository.list({
        paginate: false,
        query: {
          $limit: 999
        }
      });

      if (response.ok) {
        setDispositivosSeguranca(response.data.map(ds => ({
          label: ds.descricao,
          value: ds.id,
          tipo: ds.tipo
        })))
      }
    })()

  }, []);

  const loadOptions = (tipo) => (query) => {
    return Promise.resolve(dispositivosSeguranca
      .filter(ds => ds.tipo === tipo && ds.label.toUpperCase().includes(query.toUpperCase())))
  }

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <span className={classes.label}>{"Proteções Fixas"}</span>
    </Grid>
    <Grid item xs={12}>
      <Field
        fullWidth
        name="protecoesFixas"
        component={AutocompleteCreatable}
        isMulti
        loadOptions={loadOptions("PROTECOES_FIXAS")}
      />
    </Grid>
    <Grid item xs={12}>
      <span className={classes.label}>{"Proteções Móveis"}</span>
    </Grid>
    <Grid item xs={12}>
      <Field
        fullWidth
        name="protecoesMoveis"
        component={AutocompleteCreatable}
        isMulti
        loadOptions={loadOptions("PROTECOES_MOVEIS")}
      />
    </Grid>
    <Grid item xs={12}>
      <span className={classes.label}>{"Dispositivos de Segurança"}</span>
    </Grid>
    <Grid item xs={12}>
      <Field
        fullWidth
        name="dispositivosSeguranca"
        component={AutocompleteCreatable}
        isMulti
        loadOptions={loadOptions("DISPOSITIVOS_SEGURANCA")}
      />
    </Grid>
    <Grid item xs={12}>
      <span className={classes.label}>{"Outros"}</span>
    </Grid>
    <Grid item xs={12}>
      <Field
        fullWidth
        name="outrosDispositivosSeguranca"
        component={AutocompleteCreatable}
        isMulti
        loadOptions={loadOptions("OUTROS")}
      />
    </Grid>
    <Grid item xs={12}>
      <span className={classes.label}>{"Observações"}</span>
    </Grid>
    <Grid item xs={12}>
      <Field
        fullWidth
        name="observacoes"
        component={TextField}
      />
    </Grid>
  </Grid>
}

export default DadosSeguranca;