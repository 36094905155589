import React, { useState } from 'react'
import { Grid, makeStyles, Collapse } from '@material-ui/core'
import { Field } from 'react-final-form'
import { TextField } from '../../Components/Fields'
import MoneyField from '../../Components/Fields/MoneyField'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3)
  },
  titleBox: {
    borderBottom: `solid 1px ${theme.palette.secondary.main}`,
    marginBottom: theme.spacing(2)
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(0.5)
  },
}))

const DadosOrcamento = (props) => {

  const [dadosOrcamentoIn, setDadosOrcamentoIn] = useState(true);
  const classes = useStyles();
    
  return <Grid container className={classes.container}>
    <Grid item xs={12} className={classes.titleBox}>
      <div onClick={() => setDadosOrcamentoIn(!dadosOrcamentoIn)}
        className={classes.title}
      >
        {`ORÇAMENTO - SERVIÇOS ${dadosOrcamentoIn ? '<<' : '>>'}`}
      </div>
    </Grid>
    
    <Grid item xs={12}>
      <Collapse in={dadosOrcamentoIn}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Field
              fullWidth
              name="horasProjeto"
              label="Qtd Horas Projeto"
              component={TextField}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Field
              fullWidth
              name="valorHoraProjeto"
              label="Valor/Hora Projeto"
              component={MoneyField}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Field
              fullWidth
              name="horasServicos"
              label="Qtd Horas Serviços"
              component={TextField}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Field
              fullWidth
              name="valorHoraServicos"
              label="Valor/Hora Serviços"
              component={MoneyField}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
    
  </Grid>
}

export default DadosOrcamento;