import React from 'react'
import { Grid, makeStyles, Button } from '@material-ui/core'
import { useListRepository } from '../../Components/Hooks';
import perigosEquipamentoRepository from '../repositories/perigosEquipamentoRepository';
import { List } from '../../Components/Common'
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  addPerigoContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  descricaoPerigo: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main
  }
}))

const PerigosEquipamentoList = (props) => {

  const classes = useStyles();
  const history = useHistory();

  const listHook = useListRepository({
    repository: perigosEquipamentoRepository,
    defaultSort: 'createdAt',
    path: `equipamentos/${props.id}/perigos`,
    query: [
      ['equipamentoId', props.id]
    ]
  })

  const listOptions = {
    fields: {
      perigo: {
        label: 'Perigo',
        format: (perigo) => <span className={classes.descricaoPerigo}>{perigo.descricao}</span>
      },
      hrnAntes: {
        label: 'Classe Anterior',
        format: (hrnAntes) => hrnAntes ? <span style={{ fontWeight: 'bold', color: hrnAntes.corFundo }}>{hrnAntes.nome}</span> : ''
      },
      hrnDepois: {
        label: 'Classe Após Melhoria',
        format: (hrnDepois) => hrnDepois ? <span style={{ fontWeight: 'bold', color: hrnDepois.corFundo }}>{hrnDepois.nome}</span> : ''
      },
    }
  }

  return <Grid container>
    <Grid item xs={12}>
      <List
        {...props}
        {...listHook}
        removeItem={listHook.removeItem}
        onClickEdit={listHook.onClickEdit}
        onClickRow={listHook.onClickEdit}
        onClickView={null}
        onClickNew={null}
        hideDownloadIcon
        listOptions={listOptions}
        emptyText='Clique no botão abaixo para adicionar perigos'
      />
    </Grid>
    <Grid item xs={12} className={classes.addPerigoContainer}>
      <Button onClick={() => history.push(`/equipamentos/${props.id}/perigos/new`)} variant='outlined' color='secondary'>
        {"Adicionar Perigo"}
      </Button>
    </Grid>
  </Grid>
}


export default PerigosEquipamentoList;