import React, { useContext } from 'react'
import {
  Switch,
  Redirect,
} from 'react-router-dom'
import * as R from 'ramda'
import { useHistory } from 'react-router';
import { 
  AccountBox, 
  Business, 
  HomeWork, 
  Apps, 
  SettingsApplications, 
  Power, 
  Settings, 
  EmojiPeople, 
  Build, 
  Warning, 
  Notes, 
  CheckBox, 
  Lock, 
  MenuBook,
  Star
} from '@material-ui/icons'
import logoWhite from '../img/logo_white.png' 
import { AppWrap, RouteByMenu} from "./../Components/AppWrap"
import { Route } from 'react-router-dom'
import Home from '../modules/home/Home';
import ClientesList from '../modules/clientes/ClientesList'
import ClienteForm from '../modules/clientes/ClienteForm'
import EquipamentosList from '../modules/equipamentos/EquipamentosList'
import EquipamentoForm from '../modules/equipamentos/EquipamentoForm'
import UsersList from '../modules/users/UsersList'
import UserForm from '../modules/users/UserForm'
import { AuthContext } from '../Components/Login'
import EmpresasList from '../modules/empresas/EmpresasList'
import EmpresaForm from '../modules/empresas/EmpresaForm'
import TiposEnergiaList from '../modules/tiposEnergia/TiposEnergiaList';
import TipoEnergiaForm from '../modules/tiposEnergia/TipoEnergiaForm';
import PerigoEquipamentoForm from '../modules/perigosEquipamento/PerigoEquipamentoForm';
import PlanoAcaoPerigoForm from '../modules/planosAcaoPerigo/PlanoAcaoPerigoForm';
import ConformidadePerigoForm from '../modules/conformidadesPerigo/ConformidadePerigoForm';
import EngenheirosList from '../modules/engenheiros/EngenheirosList';
import EngenheiroForm from '../modules/engenheiros/EngenheiroForm';
import ConfiguracoesForm from '../modules/configuracoes/ConfiguracoesForm';
import MateriaisList from '../modules/materiais/MateriaisList';
import MaterialForm from '../modules/materiais/MaterialForm';
import PerigosList from '../modules/perigos/PerigosList';
import PerigoForm from '../modules/perigos/PerigoForm';
import PlanosAcaoList from '../modules/planosAcao/PlanosAcaoList';
import PlanoAcaoForm from '../modules/planosAcao/PlanoAcaoForm';
import ConformidadesList from '../modules/conformidades/ConformidadesList';
import ConformidadeForm from '../modules/conformidades/ConformidadeForm';
import DispositivosSegurancaList from '../modules/dispositivosSeguranca/DispositivosSegurancaList';
import DispositivosSegurancaForm from '../modules/dispositivosSeguranca/DispositivosSegurancaForm';
import BookSafetyList from '../modules/bookSafety/BookSafetyList';
import EquipamentosCompartilhadosList from '../modules/equipamentosCompartilhados/EquipamentosCompartilhadosList';
import EquipamentoCompartilhadoView from '../modules/equipamentosCompartilhados/EquipamentoCompartilhadoView';
import { Paper } from '@material-ui/core';

export const AppLoggedRouter = () => {
  const authContext = useContext(AuthContext);
  const user = authContext.user;
  const history = useHistory();

  const Logo = () => ( 
    <div style={{color: 'white', display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'center'}} onClick={() => history.push('/')}> 
      <img
        alt='logo'
        src={logoWhite}
        style={{ height: 40 }} />
    </div> 
  )
  
  const compartilhados = () => {
    if (!user.compartilhamento) return [];
    return [
      {
        name: 'equipamentos-compartilhados',
        label: 'Compartilhados',
        icon: Star,
        pathname: '/equipamentos-compartilhados',
        list: EquipamentosCompartilhadosList,
        form: EquipamentoCompartilhadoView
      }
    ]
  }

  let menuItems = [
    ...compartilhados(),
    {
      name: 'clientes',
      label: 'Clientes',
      icon: Business,
      pathname: '/clientes',
      list: ClientesList,
      form: ClienteForm
    },
    {
      name: 'equipamentos',
      label: 'Equipamentos',
      icon: SettingsApplications,
      pathname: '/equipamentos',
      list: EquipamentosList,
      form: EquipamentoForm
    },
    {
      name: 'bookSafety',
      label: 'Books Gerados',
      icon: MenuBook,
      pathname: '/book-safety',
      list: BookSafetyList,
      form: Paper
    },
    {
      label: 'Configurações',
      group: true,
      icon: Settings,
      items: [        
        {
          name: 'engenheiros',
          label: 'Engenheiros',
          icon: EmojiPeople,
          pathname: '/engenheiros',
          list: EngenheirosList,
          form: EngenheiroForm
        },
        {
          name: 'materiais',
          label: 'Materiais',
          icon: Build,
          pathname: '/materiais',
          list: MateriaisList,
          form: MaterialForm
        },
        {
          name: 'perigos',
          label: 'Perigos',
          icon: Warning,
          pathname: '/perigos',
          list: PerigosList,
          form: PerigoForm
        },
        {
          name: 'planos-acao',
          label: 'Planos de Ação',
          icon: Notes,
          pathname: '/planos-acao',
          list: PlanosAcaoList,
          form: PlanoAcaoForm
        },
        {
          name: 'conformidades',
          label: 'Conformidades',
          icon: CheckBox,
          pathname: '/conformidades',
          list: ConformidadesList,
          form: ConformidadeForm
        },
        {
          name: 'dispositivos-seguranca',
          label: 'Dispositivos Segurança',
          icon: Lock,
          pathname: '/dispositivos-seguranca',
          list: DispositivosSegurancaList,
          form: DispositivosSegurancaForm
        },
        {
          name: 'configuracoes',
          label: 'Empresa',
          icon: HomeWork,
          pathname: '/configuracoes',
          list: ConfiguracoesForm,
          form: Paper
        },
      ]
    }
  ];

  if (user.role === 'ADMIN') {
    menuItems.push({
      label: 'Administrador',
      group: true,
      icon: Apps,
      items: [
        {
          name: 'empresas',
          label: 'Empresas',
          icon: HomeWork,
          pathname: '/empresas',
          list: EmpresasList,
          form: EmpresaForm
        },
        {
          name: 'users',
          label: 'Usuários',
          icon: AccountBox,
          pathname: '/users',
          list: UsersList,
          form: UserForm
        },
        {
          name: 'tipos-energia',
          label: 'Tipos de Energia',
          icon: Power,
          pathname: '/tipos-energia',
          list: TiposEnergiaList,
          form: TipoEnergiaForm
        },
      ]
    })
  }

  const defaultRedirect = R.pathOr('/', [0, 'pathname'], menuItems)

  return (
    <AppWrap
      logo={<Logo />}
      userAvatarProps={{
        action: 'Sair'
      }}
      menuItems={menuItems}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/equipamentos/:equipamentoId/perigos/:id/planos-acao/:childId" exact component={PlanoAcaoPerigoForm} />
        <Route path="/equipamentos/:equipamentoId/perigos/:id/conformidades/:childId" exact component={ConformidadePerigoForm} />
        <Route path="/equipamentos/:id/perigos/:childId" exact component={PerigoEquipamentoForm} />
        <RouteByMenu menuItems={menuItems} />
        <Redirect to={defaultRedirect} />
      </Switch>
    </AppWrap>
  )
}

