import React from 'react'
import { Filter, List } from '../../Components/Common'
import { useFilter, useListRepository } from '../../Components/Hooks'
import { TextField } from '../../Components/Fields'
import { Field } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import perigosRepository from '../repositories/perigosRepository'

const PerigosFilter = () => {
  const filter = useFilter({})
  return (
    <Filter {...filter} labels={{ find: 'Buscar', clear: 'Limpar' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            fullWidth
            name="descricao"
            label="Descrição"
            component={TextField}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

const PerigosList = ({ ...props }) => {
  const listOptions = {
    defaultOrder: 'descricao',
    fields: {
      descricao: {
        label: 'Descrição',
      },
    }
  }

  const listHook = useListRepository({
    repository: perigosRepository,
    defaultSort: 'descricao asc',
    path: 'perigos'
  })
  
  return (
    <>
      <PerigosFilter />
      <List
        {...props}
        {...listHook}
        removeItem={listHook.removeItem}
        onClickEdit={listHook.onClickEdit}
        onClickNew={listHook.onClickNew}
        onClickView={null}
        hideDownloadIcon
        listOptions={listOptions}
      />
    </>
  )
}

export default PerigosList
