import React from 'react'
import { Filter, List } from '../../Components/Common'
import { useFilter, useListRepository } from '../../Components/Hooks'
import { TextField } from '../../Components/Fields'
import { Field } from 'react-final-form'
import { Grid } from '@material-ui/core'
import equipamentosCompartilhadosRepository from '../repositories/equipamentosCompartilhadosRepository'

const EquipamentosCompartilhadosFilter = () => {
  const filter = useFilter({});

  return (
    <Filter {...filter} labels={{ find: 'Buscar', clear: 'Limpar' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            fullWidth
            name="nome"
            label="Nome"
            component={TextField}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

const EquipamentosList = ({ ...props }) => {

  const listOptions = {
    fields: {
      nome: {
        label: 'Nome',
      }
    }
  }

  const listHook = useListRepository({
    repository: equipamentosCompartilhadosRepository,
    defaultSort: 'nome asc',
    path: 'equipamentos-compartilhados'
  })
  
  return (
    <>
      <EquipamentosCompartilhadosFilter />
      <List
        {...props}
        {...listHook}
        removeItem={null}
        onClickEdit={null}
        onClickRow={listHook.onClickEdit}
        onClickNew={null}
        onClickView={null}
        hideDownloadIcon
        listOptions={listOptions}
      />
    </>
  )
}

export default EquipamentosList
