import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Field } from 'react-final-form'
import withoutLogo from '../../img/no_logo.png'
import { ImagePicker } from '../../Components/Common'

const useStyles = makeStyles(theme => ({
  fieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
}))

const PlantaBaixa = () => {

  const classes = useStyles();

  return <Grid container spacing={2}>
    <Grid item xs={12} className={classes.fieldContainer}>
      <Field
        name="plantaBaixaUrl"
        defaultImage={withoutLogo}
        size={250}
        component={ImagePicker}
      />
    </Grid>
    
  </Grid>
}

export default PlantaBaixa;