import React, { useState } from 'react'
import { Grid, makeStyles, Collapse } from '@material-ui/core'
import DadosIdentificacao from './DadosIdentificacaoView'
import DadosFabricante from './DadosFabricanteView'
import DadosEnergia from './DadosEnergiaView'
import DadosSeguranca from './DadosSegurancaView'
import DadosDocumentacao from './DadosDocumentacaoView'
import PlantaBaixa from './PlantaBaixaView'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3)
  },
  titleBox: {
    borderBottom: `solid 1px ${theme.palette.secondary.main}`
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(0.5)
  },
  secoesContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  secaoSelecionada: {
    width: 200,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    border: `solid 1px ${theme.palette.secondary.main}`,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    display: 'flex',
    justifyContent: 'center'
  },
  secao: {
    cursor: 'pointer',
    width: 200,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    border: `solid 1px ${theme.palette.secondary.main}`,
    borderRadius: 10,
    color: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center'
  },
}))

const DadosCadastraisView = (props) => {

  const classes = useStyles();
  const [dadosCadastraisIn, setDadosCadastraisIn] = useState(true);
  const [secaoSelecionada, setSecaoSelecionada] = useState('Identificação');

  const ButtonSecao = ({ nomeSecao }) => {    
    return <Grid item className={nomeSecao === secaoSelecionada ? classes.secaoSelecionada : classes.secao} 
      onClick={() => setSecaoSelecionada(nomeSecao)}
    >
      {nomeSecao}
    </Grid>
  }
    
  return <Grid container className={classes.container}>
    <Grid item xs={12} className={classes.titleBox}>
      <div onClick={() => setDadosCadastraisIn(!dadosCadastraisIn)}
        className={classes.title}
      >
        {`DADOS CADASTRAIS ${dadosCadastraisIn ? '<<' : '>>'}`}
      </div>
    </Grid>
    
    <Grid item xs={12}>
      <Collapse in={dadosCadastraisIn}>
        <Grid container className={classes.secoesContainer}>
          <ButtonSecao nomeSecao="Identificação" />
          <ButtonSecao nomeSecao="Fabricante" />
          <ButtonSecao nomeSecao="Energia" />
          <ButtonSecao nomeSecao="Segurança" />
          <ButtonSecao nomeSecao="Documentação" />
          <ButtonSecao nomeSecao="Planta Baixa" />
        </Grid>

        {secaoSelecionada === 'Identificação' && <DadosIdentificacao {...props} />}
        {secaoSelecionada === 'Fabricante' && <DadosFabricante {...props} />}
        {secaoSelecionada === 'Energia' && <DadosEnergia {...props} />}
        {secaoSelecionada === 'Segurança' && <DadosSeguranca {...props} />}
        {secaoSelecionada === 'Documentação' && <DadosDocumentacao {...props} />}
        {secaoSelecionada === 'Planta Baixa' && <PlantaBaixa {...props} />}
      </Collapse>
    </Grid>
    
  </Grid>
}

export default DadosCadastraisView;