import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Field } from 'react-final-form'
import { AutocompleteCreatable, Select, TextField } from '../../Components/Fields'
import planosAcaoRepository from '../repositories/planosAcaoRepository'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import planosAcaoPerigoRepository from '../repositories/planosAcaoPerigoRepository'
import { useHistory } from 'react-router'
import itensNormaRepository from '../repositories/itensNormaRepository'
import DateField from '../../Components/Fields/DateField'
import FotosPlanoAcao from './FotosPlanoAcao'
import MateriaisPlanoAcao from './MateriaisPlanoAcao'
import arrayMutators from 'final-form-arrays'

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  mustSaveContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  mustSaveText: {
    fontSize: 14,
    color: theme.palette.secondary.main
  },
  mustSaveLink: {
    marginLeft: 4,
    fontSize: 14,
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
}))

const PlanoAcaoPerigoForm = (props) => {

  const classes = useStyles();

  const [message, setMessage] = useState();
  
  const [itensNorma, setItensNorma] = useState([]);

  useEffect(() => {
    ;(async () => {
      const response = await itensNormaRepository.list();

      if (response.ok) {
        setItensNorma(response.data.map(it => ({
          label: `${it.indice} - ${it.descricao}`,
          value: it.id
        })))
      }
    })()

  }, []);

  const history = useHistory();
  
  const onGoBack = () => {
    let currentPathname = history.location.pathname
    history.push(currentPathname.substring(0, currentPathname.indexOf("planos-acao") - 1));
  }

  const entityManager = useEntityManager({
    repository: planosAcaoPerigoRepository,
    handleError: data => data.message,
    relationKey: 'perigoEquipamentoId',
    onGoBack
  });

  const loadOptions = async (query) => {
    
    const response = await planosAcaoRepository.list({
      paginate: false,
      query: {
        descricao: query
      }
    });

    if (response.ok) {
      return response.data.map(pa => ({
        label: pa.descricao,
        value: pa.id
      }))
    } else {
      return []
    }
  }

  const validate = (values) => {
    const errors = {};
    if (values.materiais) {
      const materiaisErrors = [];

      for (const mat of values.materiais) {
        materiaisErrors.push({
          material: mat.material ? undefined : 'Obrigatório'
        })
      }

      errors.materiais = materiaisErrors;
    }

    return errors;
  }

  return <CrudForm
    {...props}
    {...entityManager}
    withPaper
    message={message}
    clearMessage={() => setMessage(undefined)}
    goBackOnSaveButton
    validate={validate}
    formProps={{
      mutators: { ...arrayMutators }
    }}
  >
    {({ form }) => {
      return <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <span className={classes.label}>{"Ações Necessárias"}</span>
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                name="itensPlanoAcao"
                component={AutocompleteCreatable}
                isMulti
                loadOptions={loadOptions}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                name="itemNormaId"
                component={Select}
                label="Item da Norma"
                options={itensNorma}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <span className={classes.label}>{"Planejamento"}</span>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                fullWidth
                name="responsavel"
                component={TextField}
                label="Responsável"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                fullWidth
                label="Data Prevista"
                component={DateField}
                name="dataPrevista"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                fullWidth
                label="Data Realizada"
                component={DateField}
                name="dataRealizada"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MateriaisPlanoAcao form={form} />
        </Grid>
        {entityManager.isNew && <Grid item xs={12} className={classes.mustSaveContainer}>
          <span className={classes.mustSaveText}>{"Para adicionar fotos, "}</span>
          <span className={classes.mustSaveLink} onClick={() => form.submit()}>{"salve as alterações"}</span>
        </Grid>}
        
        {!entityManager.isNew && <Grid item xs={12}>
          <FotosPlanoAcao planoAcaoPerigoId={entityManager.childId} setMessage={setMessage} />
        </Grid>}
      </Grid>
    }}
  </CrudForm>
}

export default PlanoAcaoPerigoForm;