import React from 'react'
import { Filter, List } from '../../Components/Common'
import { useFilter, useListRepository } from '../../Components/Hooks'
import { Select, TextField } from '../../Components/Fields'
import { Field } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import dispositivosSegurancaRepository from '../repositories/dispositivosSegurancaRepository'

const DispositivosSegurancaFilter = () => {
  const filter = useFilter({})
  return (
    <Filter {...filter} labels={{ find: 'Buscar', clear: 'Limpar' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Field
            fullWidth
            name="tipo"
            label="Tipo"
            component={Select}
            options={[
              {
                label: 'Proteçoes Fixas',
                value: 'PROTECOES_FIXAS'
              }, 
              {
                label: 'Proteções Móveis',
                value: 'PROTECOES_MOVEIS'
              }, 
              {
                label: 'Dispositivos Segurança',
                value: 'DISPOSITIVOS_SEGURANCA'
              }, 
              {
                label: 'Outros',
                value: 'OUTROS'
              }
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            fullWidth
            name="descricao"
            label="Descrição"
            component={TextField}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

const DispositivosSegurancaList = ({ ...props }) => {
  const listOptions = {
    defaultOrder: 'tipo',
    fields: {
      tipo: {
        label: 'Tipo',
      },
      descricao: {
        label: 'Descrição',
      },
    }
  }

  const listHook = useListRepository({
    repository: dispositivosSegurancaRepository,
    defaultSort: 'descricao asc',
    path: 'dispositivos-seguranca'
  })
  
  return (
    <>
      <DispositivosSegurancaFilter />
      <List
        {...props}
        {...listHook}
        removeItem={listHook.removeItem}
        onClickEdit={listHook.onClickEdit}
        onClickNew={listHook.onClickNew}
        onClickView={null}
        hideDownloadIcon
        listOptions={listOptions}
      />
    </>
  )
}

export default DispositivosSegurancaList
