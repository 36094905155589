import config from '../config'
import { create } from 'apisauce'

export const brasilApi = create({
  baseURL: config.brasilApiURL
})

const getApi = () => {

  return {
    getCnpj: cnpj => brasilApi.get(`/cnpj/v1/${cnpj}`),
  }
}

export default getApi()
