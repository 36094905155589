import React, { useEffect, useState } from 'react'
import PureAutocomplete from '../Common/Autocomplete'

const Autocomplete = ({ input, meta, options, showError, selectedOptions = [], ...props }) => {
  const [value, setValue] = useState()
  useEffect(() => {
    const fetch = async () => {
      if (options) {
        // eslint-disable-next-line eqeqeq
        const option = options.find(o => o.value == input.value) || ''
        setValue(option)
      }
    }
    fetch()
  }, [input.value, options])

  const onChange = (item, action) => {
    setValue(item)
    input.onChange(item ? item.value : '')
    if (props.onChange) props.onChange(item, action)
  }

  const touched = meta.touched || showError

  return (
    <PureAutocomplete
      {...props}
      meta={meta}
      error={meta.error && touched}
      textFieldProps={{
        onBlur: input.onBlur,
        helperText: touched && meta.error,
        error: meta.error && touched,
        InputLabelProps: {
          shrink: !!input.value || input.dirty
        }
      }}
      helperText={touched && meta.error}
      options={options}
      onChange={onChange}
      value={value}
      selectedOptions={selectedOptions}
    />
  )
}

export default Autocomplete
