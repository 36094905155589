import { restApi } from '../../services/api'
import { createBasicRepository as createRepository } from '../../Components/Repository'
import { paginate } from '../../lib/feathers'

const planosAcaoPerigoRepository = createRepository({
  path: 'planos-acao-perigo',
  restApi,
  queryTransform: {
    ...paginate
  },
  dataTransform: (data) => {
    if (data.materiais) {
      for (const mat of data.materiais) {
        if (mat.id && !mat.op) {
          mat.op = 'replace'
        }
      }
    }

    return data;
  }
})

export default planosAcaoPerigoRepository
