import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { CloudDownload, Add } from '@material-ui/icons';
import PickFile from './PickFile';

const styles = theme => ({
  label: {
    fontSize: '14px',
  },
  iconButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.white,
    '&:hover': {
      background: theme.palette.primary.main,
    }
  },
  darken: {
    filter: 'gray', /* IE6-9 */
    '-webkit-filter': 'brightness(40%)', /* Chrome 19+, Safari 6+, Safari 6+ iOS */
    transition: 'filter 0.5s',
  },
  normal: {
    filter: 'gray', /* IE6-9 */
    '-webkit-filter': 'brightness(100%)', /* Chrome 19+, Safari 6+, Safari 6+ iOS */
    transition: 'filter 0.5s',
  },
  avatarControlsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
})

const download = (url, fileName) => {
  var a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

const EditControls = ({classes, id, onChange, accept, stringData, filename, ...props}) => (
  <div
    {...props}
    className={classes.avatarControlsContainer}>    
    <IconButton className={classes.iconButton} onClick={() => download(stringData, filename)}>
      <CloudDownload />
    </IconButton>    
    <PickFile onChange={onChange} id={id} accept={accept}>
      <IconButton component='span' className={classes.iconButton}>
        <EditIcon />
      </IconButton>
    </PickFile>
    <IconButton onClick={() => onChange && onChange(undefined)} className={classes.iconButton}>
      <DeleteIcon />
    </IconButton>
  </div>
)

const NewControls = ({classes, onChange, id, accept, ...props}) => (
  <div
    {...props}
    className={classes.avatarControlsContainer}>
    <PickFile onChange={onChange} id={id} accept={accept}>
      <IconButton component='span' className={classes.iconButton}>
        <Add />
      </IconButton>
    </PickFile>
  </div>
)
function FilePicker({classes, id='file-pick', accept, value, onChange, filename}) {
  const hasValue = !!value
  
  const getStringData = () => {
    if (typeof value === 'string')
      return value
    return value[0].dataURL
  }

  return (
    <div style={{width: '100%'}}>
      {!hasValue && (
        <NewControls
          accept={accept}
          classes={classes}
          id={id}
          onChange={onChange}/>
      )}
      {hasValue && (
        <EditControls
          accept={accept}
          onChange={onChange}
          id={id}
          classes={classes}
          filename={filename}
          stringData={getStringData()}/>
      )}
    </div>
  )
}

function FilePickerField({input, label, meta, ...props}) {
  return (
    <div style={{width: 200}}>
      {
        label ?
          <InputLabel htmlFor="my-input" className={props.classes.label}>{label}</InputLabel> : null
      }
      <div style={{marginTop: 8, width: '100%'}}/>
      <FilePicker
        {...props}
        id={input.name}
        value={input.value}
        onChange={input.onChange}/>
    </div>
  )
}


export default withStyles(styles)(FilePickerField)
