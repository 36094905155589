import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add';
import PickImage from './PickImage'

const styles = theme => ({
  iconButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.white,
    '&:hover': {
      background: theme.palette.primary.main,
    }
  },
  avatarControlsContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }
})


const NewControls = ({classes, onChange, id, ...props}) => (
  <div
    {...props}
    className={classes.avatarControlsContainer}>
    <PickImage onChange={onChange} id={id}>
      <IconButton component='span' className={classes.iconButton}>
        <AddIcon />
      </IconButton>
    </PickImage>
  </div>
)

function AddImageInput({classes, id='image-pick', backgroundImage, onChange, size}) {

  return (
    <div style={{position: 'relative', height: size, width: size}}>
      <img
        alt={id}
        onError={() => onChange(null)}
        src={backgroundImage}
        style={{height: '100%', width: '100%', objectFit: "contain"}}
      />

        <NewControls
          classes={classes}
          id={id}
          onChange={onChange}/>
      
    </div>
  )
}

export default withStyles(styles)(AddImageInput)
