import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Field } from 'react-final-form'
import { AutocompleteCreatable, Select, TextField } from '../../Components/Fields'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import perigosEquipamentoRepository from '../repositories/perigosEquipamentoRepository'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'
import { setValue } from '../../utils/utils'
import perigosRepository from '../repositories/perigosRepository'
import fatoresHrnRepository from '../repositories/fatoresHrnRepository'
import { makeStyles } from '@material-ui/core'
import PlanosAcaoPerigoList from '../planosAcaoPerigo/PlanosAcaoPerigoList'
import { useHistory } from 'react-router-dom'
import ConformidadadesPerigoList from '../conformidadesPerigo/ConformidadadesPerigoList'

const useStyles = makeStyles(theme => ({
  classeHrn: {
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  categoriaSegurancaContainer: {
    backgroundColor: theme.palette.grey[200],
  },
  categoriaSeguranca: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  mustSaveContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  mustSaveText: {
    fontSize: 14,
    color: theme.palette.secondary.main
  },
  mustSaveLink: {
    marginLeft: 4,
    fontSize: 14,
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
}))

export const formatCategoriaSeguranca = (categoria) => {
  if (categoria === 'CATEGORIA_1') return "1";
  if (categoria === 'CATEGORIAS_1_2') return "1, 2";
  if (categoria === 'CATEGORIAS_2_3') return "2, 3";
  if (categoria === 'CATEGORIA_3') return "3";
  if (categoria === 'CATEGORIA_4') return "4";
}

const schema = yup.object().shape({
  perigo: yup.string().required('Obrigatório'),
})

const validate = yupValidation(schema)

const PerigoEquipamentoForm = props => {

  const [fatoresHrn, setFatoresHrn] = useState([]);

  useEffect(() => {
    ;(async () => {
      const response = await fatoresHrnRepository.list();

      if (response.ok) {
        setFatoresHrn(response.data.map(f => ({
          label: `(${f.fator}) ${f.descricao}`,
          value: f.id,
          tipo: f.tipo
        })))
      }
    })()

  }, []);
  
  const history = useHistory();
  
  const onGoBack = () => {
    let currentPathname = history.location.pathname
    history.push(currentPathname.substring(0, currentPathname.indexOf("perigos") - 1));
  }

  const entityManager = useEntityManager({
    repository: perigosEquipamentoRepository,
    handleError: data => data.message,
    relationKey: 'equipamentoId',
    onGoBack
  });

  const perigoLoadOptions = async (query) => {
    
    const response = await perigosRepository.list({
      paginate: false,
      query: {
        descricao: query
      }
    });

    if (response.ok) {
      return response.data.map(p => ({
        label: p.descricao,
        value: p.id
      }))
    } else {
      return []
    }
    
  }

  const setFatorDepois = (form, values, field, newValue) => {
    if (!values[field]) {
      form.mutators.setValue(field, newValue);
    }
  }

  const { item } = entityManager;
  const classes = useStyles();

  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        validate={validate}
        withPaper
        formProps={{
          mutators: { setValue }
        }}
        goBackOnSaveButton
      >
        {({ form, values }) => {

          const isDirty = entityManager.isNew || form.getState().dirty;

          return <Grid container spacing={2}>
            <Grid item xs={12} >
              <Field
                fullWidth
                name="perigo"
                label="Descrição do Perigo"
                component={AutocompleteCreatable}
                loadOptions={perigoLoadOptions}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} >
              <Field
                fullWidth
                name="npAntesId"
                label="Quantidade Pessoas (Antes)"
                component={Select}
                options={fatoresHrn.filter(f => f.tipo === 'NP')}
                onChange={(event) => setFatorDepois(form, values, 'npDepoisId', event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
              <Field
                fullWidth
                name="dphAntesId"
                label="Grau Possível Lesão (Antes)"
                component={Select}
                options={fatoresHrn.filter(f => f.tipo === 'DPH')}
                onChange={(event) => setFatorDepois(form, values, 'dphDepoisId', event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
              <Field
                fullWidth
                name="feAntesId"
                label="Frequência Exposição (Antes)"
                component={Select}
                options={fatoresHrn.filter(f => f.tipo === 'FE')}
                onChange={(event) => setFatorDepois(form, values, 'feDepoisId', event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
              <Field
                fullWidth
                name="loAntesId"
                label="Probabilidade Ocorrência (Antes)"
                component={Select}
                options={fatoresHrn.filter(f => f.tipo === 'LO')}
                onChange={(event) => setFatorDepois(form, values, 'loDepoisId', event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
              <Field
                fullWidth
                name="npDepoisId"
                label="Quantidade Pessoas (Depois)"
                component={Select}
                options={fatoresHrn.filter(f => f.tipo === 'NP')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
              <Field
                fullWidth
                name="dphDepoisId"
                label="Grau Possível Lesão (Depois)"
                component={Select}
                options={fatoresHrn.filter(f => f.tipo === 'DPH')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
              <Field
                fullWidth
                name="feDepoisId"
                label="Frequência Exposição (Depois)"
                component={Select}
                options={fatoresHrn.filter(f => f.tipo === 'FE')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
              <Field
                fullWidth
                name="loDepoisId"
                label="Probabilidade Ocorrência (Depois)"
                component={Select}
                options={fatoresHrn.filter(f => f.tipo === 'LO')}
              />
            </Grid>

            {item.hrnAntes && item.hrnDepois && <>
              <Grid item xs={12} sm={6}>
                <div style={{ 
                  backgroundColor: item.hrnAntes.corFundo, 
                  color: item.hrnAntes.corTexto,
                  width: '100%',
                }}>
                  <div className={classes.classeHrn}>{`HRN Antes: ${item.valorHrnAntes} - ${item.hrnAntes.nome}`}</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ 
                  backgroundColor: item.hrnDepois.corFundo, 
                  color: item.hrnDepois.corTexto,
                  width: '100%'
                }}>
                  <div className={classes.classeHrn}>{`HRN Depois: ${item.valorHrnDepois} - ${item.hrnDepois.nome}`}</div>
                </div>
              </Grid>
            </>}

            <Grid item xs={12} sm={6} md={3} >
              <Field
                fullWidth
                name="possibilidadeEvitar"
                label="Possibilidade de Evitar"
                component={Select}
                options={[
                  {
                    value: 'POSSIVEL',
                    label: 'Possível'
                  },
                  {
                    value: 'POUCO_POSSIVEL',
                    label: 'Pouco Possível'
                  },
                  {
                    value: 'NAO_APLICAVEL',
                    label: 'Não Aplicável'
                  }
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={9} >
              <Field
                fullWidth
                name="observacao"
                label="Observações"
                component={TextField}
                multiline
              />
            </Grid>
            
            {item.categoriaSeguranca && <Grid item xs={12}>
              <div className={classes.categoriaSegurancaContainer}>
                <div className={classes.categoriaSeguranca}>
                  {`Categorias de Segurança: [ ${formatCategoriaSeguranca(item.categoriaSeguranca)} ]`}
                </div>
              </div>
            </Grid>}

            {isDirty && <Grid item xs={12} className={classes.mustSaveContainer}>
              <span className={classes.mustSaveText}>{"Para adicionar e editar planos de ação, "}</span>
              <span className={classes.mustSaveLink} onClick={() => form.submit()}>{"salve as alterações"}</span>
            </Grid>}
            
            {!isDirty && <PlanosAcaoPerigoList equipamentoId={entityManager.id} perigoId={entityManager.childId}/>}
            
            {!isDirty && <ConformidadadesPerigoList equipamentoId={entityManager.id} perigoId={entityManager.childId}/>}

          </Grid>
        }}
      </CrudForm>
    </>
  )
}

export default PerigoEquipamentoForm
