import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useListRepository } from '../../Components/Hooks'
import { Button, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router'
import { List } from '../../Components/Common'
import planosAcaoPerigoRepository from '../repositories/planosAcaoPerigoRepository'

const useStyles = makeStyles(theme => ({
  addPlanoAcaoContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
}))

const PlanosAcaoPerigoList = props => {

  const planosAcaoListHook = useListRepository({
    repository: planosAcaoPerigoRepository,
    defaultSort: 'createdAt',
    path: `equipamentos/${props.equipamentoId}/perigos/${props.perigoId}/planos-acao`,
    query: [
      ['perigoEquipamentoId', props.perigoId]
    ]
  });

  const planosAcaoListOptions = {
    fields: {
      itensPlanoAcao: {
        label: 'Planos de Ação',
        format: (planosAcao) => planosAcao.map(pa => pa.label).join(', ') || 'A descrever'
      },
    }
  }

  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Grid item xs={12}>
        <List
          {...props}
          {...planosAcaoListHook}
          removeItem={planosAcaoListHook.removeItem}
          onClickEdit={planosAcaoListHook.onClickEdit}
          onClickRow={planosAcaoListHook.onClickEdit}
          onClickView={null}
          onClickNew={null}
          hideDownloadIcon
          listOptions={planosAcaoListOptions}
          emptyText='Clique no botão abaixo para adicionar planos de ação'
        />
      </Grid>
      <Grid item xs={12} className={classes.addPlanoAcaoContainer}>
        <Button onClick={() => history.push(`/equipamentos/${props.equipamentoId}/perigos/${props.perigoId}/planos-acao/new`)} variant='outlined' color='secondary'>
          {"Adicionar Plano de Ação"}
        </Button>
      </Grid>
    </>
  )
}

export default PlanosAcaoPerigoList
