import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import ReadInput from '../../Components/Common/ReadInput';

const useStyles = makeStyles(theme => ({  
  container: {
    padding: theme.spacing(2)
  },
  fotosContainer: {
    display: 'flex'
  },
  fotoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  foto: {
    width: 150,
    height: 150,
    objectFit: 'cover'
  },
  obsFoto: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    fontStyle: 'italic'
  }
}));

const ConformidadeView = ({ item }) => {

  const classes = useStyles();

  return <Grid container spacing={2} className={classes.container}>
    <Grid item xs={12}>
      <ReadInput
        value={item.itemNorma ? `${item.itemNorma.indice} - ${item.itemNorma.descricao}` : ''}
        label="Item da Norma"
      />
    </Grid>    
    {item.fotos.length > 0 && <>
      <Grid item xs={12} className={classes.fotosContainer}>
        {item.fotos.map(f => <div key={f.id} className={classes.fotoContainer}>        
          <img
            className={classes.foto}
            src={f.data}
            alt="Foto da Conformidade"
          />
        </div>)}
      </Grid>
      <Grid item xs={12}>
        <span className={classes.obsFoto}>{"*Foi retirada a visibilidade das fotos para preservar a privacidade da empresa."}</span>
      </Grid>
    </>}
  </Grid>
}

export default ConformidadeView
