import React from 'react'
import {RecoverPassword} from '../../Components/Login'
import api from '../../services/api'
import logo from '../../img/logo.png'

export const handleSubmit = (init, location) => async ({username}) => {
  const params = new URLSearchParams(location.search)
  const response = await init({
    action: params.get('action'),
    password: username,
    token: params.get('token')
  })
  if (response.ok) {
    return Promise.resolve({ok: true, message: 'Senha redefinida com sucesso!'})
  }
  return {ok: false, message: 'Token inválido'}
}

export default function CustomRecoverPassword({history, location}) {


  return (
    <RecoverPassword
      logo={
        <img
          alt='logo'
          src={logo}
          style={{ height: 280, marginBottom: 20 }} />
      }
      history={history}
      fieldType='password'
      usernameLabel='Nova senha'
      backLabel='Ir para Login'
      submitLabel='Redefinir senha'
      onBackClick={() => history.push('/login')}
      onSubmit={handleSubmit(api.resetPassword, location)}
    />
  )
}


