import { restApi } from '../../services/api'
import { createBasicRepository as createRepository } from '../../Components/Repository'
import { paginate } from '../../lib/feathers'

const perigosEquipamentoRepository = createRepository({
  path: 'perigos-equipamento',
  restApi,
  queryTransform: {
    ...paginate
  },
  dataTransform: (data) => {

    data.completePatch = true;

    return data;
  }
})

export default perigosEquipamentoRepository
