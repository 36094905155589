import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Field } from 'react-final-form'
import { Select, TextField, Autocomplete } from '../../Components/Fields'
import { CrudForm, ImagePicker } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import equipamentosRepository from '../repositories/equipamentosRepository'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'
import withoutLogo from '../../img/no_logo.png'
import { makeStyles } from '@material-ui/core'
import DadosCadastrais from './DadosCadastrais'
import DadosRisco from './DadosRisco'
import arrayMutators from 'final-form-arrays'
import { setValue } from '../../utils/utils'
import useClientesOptions from '../clientes/useClientesOptions'
import DadosOrcamento from './DadosOrcamento'
import DescriptionIcon from '@material-ui/icons/Description'
import { restApi } from '../../services/api'
import { saveAs } from 'file-saver'

const useStyles = makeStyles(theme => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  downloadButton: {
    width: 250,
    display: 'flex',
    justifyContent: 'space-around'
  }
}))

const schema = yup.object().shape({
  nome: yup.string().required('Obrigatório'),
  clienteId: yup.number().required('Obrigatório')
})

const validate = yupValidation(schema)

const EquipamentoForm = props => {

  const [downloading, setDownloading] = useState(false);

  const entityManager = useEntityManager({
    repository: equipamentosRepository,
    handleError: data => data.message
  })

  const classes = useStyles();

  const clientesOptions = useClientesOptions();  

  const downloadPDF = async () => {
    setDownloading(true);
    const response = await restApi.get(`equipamento-report/${entityManager.id}`, {}, { responseType: `blob` })
    try {
      if (response.ok && response.data) {
        saveAs(response.data, `${entityManager.item?.nome || "NR-12 Equipamento"}.pdf`)
      } else {
        entityManager.setError("Erro ao gerar relatório");
      }
    } catch (e) {
      entityManager.setError("Erro ao gerar relatório");
    } finally {
      setDownloading(false);
    }
  }

  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        validate={validate}
        withPaper
        initialValuesCreate={{
          maquinas: [{ op: 'add' }],
        }}
        formProps={{
          mutators: { ...arrayMutators, setValue }
        }}
        customActions={[{
          label: 'Baixar Relatório NR-12',
          onClick: downloadPDF,
          icon: <DescriptionIcon/>,
          loading: downloading,
          disabled: (form) => entityManager.isNew || form.getState().dirty,
          props: {
            color: 'secondary',
            className: classes.downloadButton
          }
        }]}
      >
        {({ form, values }) => <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} className={classes.logoContainer} >
              <Field
                name="fotoUrl"
                defaultImage={withoutLogo}
                size={180}
                component={ImagePicker}
                label="Foto"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={9} xl={10} >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="clienteId"
                    label="Cliente"
                    component={Autocomplete}
                    {...clientesOptions}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="nome"
                    label="Identificação"
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    fullWidth
                    name="codigo"
                    label="Código do Equipamento"
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    fullWidth
                    name="status"
                    label="Status"
                    component={Select}
                    options={[
                      {
                        label: "Ativo",
                        value: "ATIVO"
                      },
                      {
                        label: "Inativo",
                        value: "INATIVO"
                      },
                      {
                        label: "Em Manutenção",
                        value: "EM_MANUTENCAO"
                      }
                    ]}
                  />
                </Grid>
                {(values.status === 'INATIVO' || values.status === 'EM_MANUTENCAO') && <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="observacaoInativo"
                    label="Conclusão sobre equipamento inativo / em manutenção"
                    component={TextField}
                  />
                </Grid>}
              </Grid>
            </Grid>          
          </Grid>

          <DadosCadastrais form={form} />
          <DadosRisco {...entityManager} form={form} />
          <DadosOrcamento />
        </>}
      </CrudForm>
    </>
  )
}

export default EquipamentoForm
