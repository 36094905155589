import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useListRepository } from '../../Components/Hooks'
import { Button, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router'
import { List } from '../../Components/Common'
import conformidadesPerigoRepository from '../repositories/conformidadesPerigoRepository'

const useStyles = makeStyles(theme => ({
  addConformidadeContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
}))

const ConformidadesList = props => {

  const listHook = useListRepository({
    repository: conformidadesPerigoRepository,
    defaultSort: 'createdAt',
    path: `equipamentos/${props.equipamentoId}/perigos/${props.perigoId}/conformidades`,
    query: [
      ['perigoEquipamentoId', props.perigoId]
    ]
  });

  const listOptions = {
    fields: {
      conformidade: {
        label: 'Conformidades',
        format: (conformidade) => conformidade?.descricao
      },
    }
  }

  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Grid item xs={12}>
        <List
          {...props}
          {...listHook}
          removeItem={listHook.removeItem}
          onClickEdit={listHook.onClickEdit}
          onClickRow={listHook.onClickEdit}
          onClickView={null}
          onClickNew={null}
          hideDownloadIcon
          listOptions={listOptions}
          emptyText='Clique no botão abaixo para adicionar conformidades'
        />
      </Grid>
      <Grid item xs={12} className={classes.addConformidadeContainer}>
        <Button onClick={() => history.push(`/equipamentos/${props.equipamentoId}/perigos/${props.perigoId}/conformidades/new`)} variant='outlined' color='secondary'>
          {"Adicionar Conformidade"}
        </Button>
      </Grid>
    </>
  )
}

export default ConformidadesList
