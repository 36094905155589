import moment from 'moment'

export const formatDate = date => {
  return moment(date).format('DD/MM/YYYY')
}

export const setValue = ([field, value], state, { changeValue }) => {
  changeValue(state, field, () => value)
}

export const dataURLtoBlob = (src, fileName, mimeType) => {
  return (fetch(src)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], fileName, {type:mimeType});})
  );
}