import { restApi } from '../../services/api'
import { createBasicRepository as createRepository } from '../../Components/Repository'

const fatoresHrnRepository = createRepository({
  path: 'fatores-hrn',
  restApi,  
  listResponsePath: {
    pathData: ['data'],
    pathCount: ['total'],
  },
})

export default fatoresHrnRepository
