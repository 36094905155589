import React, { useState } from 'react'
import { Grid, makeStyles, Collapse } from '@material-ui/core'
import PerigosEquipamentoList from '../perigosEquipamento/PerigosEquipamentoList';
import { Field } from 'react-final-form'
import { Select } from '../../Components/Fields'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3)
  },
  titleBox: {
    borderBottom: `solid 1px ${theme.palette.secondary.main}`
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(0.5)
  },
  mustSaveContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  mustSaveText: {
    fontSize: 14,
    color: theme.palette.secondary.main
  },
  mustSaveLink: {
    marginLeft: 4,
    fontSize: 14,
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  conformidadeContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(3)
  }
}))

const DadosRisco = (props) => {

  const classes = useStyles();
  const [dadosRiscoIn, setDadosRiscoIn] = useState(true); 

  const isDirty = props.isNew || props.form.getState().dirty;
    
  return <Grid container className={classes.container}>
    <Grid item xs={12} className={classes.titleBox}>
      <div onClick={() => setDadosRiscoIn(!dadosRiscoIn)}
        className={classes.title}
      >
        {`ANÁLISE DE RISCO ${dadosRiscoIn ? '<<' : '>>'}`}
      </div>
    </Grid>
    
    <Grid item xs={12}>
      <Collapse in={dadosRiscoIn}>

        {isDirty && <div className={classes.mustSaveContainer}>
          <span className={classes.mustSaveText}>{"Para adicionar e editar perigos, "}</span>
          <span className={classes.mustSaveLink} onClick={() => props.form.submit()}>{"salve as alterações"}</span>
        </div>}
        
        {!isDirty && <PerigosEquipamentoList {...props} />}
        
        <Grid container className={classes.conformidadeContainer}>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="conformidade"
              label="Equipamento está em conformdiade com a norma?"
              component={Select}
              options={[
                {
                  label: 'SIM',
                  value: 'SIM'
                }, 
                {
                  label: 'PARCIALMENTE',
                  value: 'PARCIAL'
                }, 
                {
                  label: 'NÃO',
                  value: 'NAO'
                }
              ]}
            />
          </Grid>
        </Grid>
        
      </Collapse>
    </Grid>
    
  </Grid>
}

export default DadosRisco;