import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { useEntityManager } from '../../Components/Hooks'
import { Snackbar } from '../../Components/Common'
import equipamentosCompartilhadosRepository from '../repositories/equipamentosCompartilhadosRepository'
import copyEquipamentoCompartilhadoRepository from '../repositories/copyEquipamentoCompartilhadoRepository'
import { Button, makeStyles, Paper } from '@material-ui/core'
import Fetching from '../../Components/Common/Fetching'
import DadosCadastraisView from './DadosCadastraisView'
import ReadInput from '../../Components/Common/ReadInput'
import DadosRiscoView from './DadosRiscoView'
import { useHistory } from 'react-router-dom'
import withoutLogo from '../../img/no_logo.png'
import { restApi } from '../../services/api'
import { saveAs } from 'file-saver'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(3)
  },
  paper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  button: {
    width: 300,
    marginLeft: theme.spacing(2)
  },
  fotoContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  foto: {
    width: 150,
    height: 150,
    objectFit: 'cover'
  },
  obsFotoContainer: {
    paddingTop: theme.spacing(1),
  },
  obsFoto: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    fontStyle: 'italic'
  }
}))

const EquipamentoCompartilhadoView = props => {

  const entityManager = useEntityManager({
    repository: equipamentosCompartilhadosRepository,
    handleError: data => data.message
  })

  const classes = useStyles();
  const { item } = entityManager;

  const [message, setMessage] = useState('');
  const [fetchingCopy, setFetchingCopy] = useState(false);  
  const [downloading, setDownloading] = useState(false);
  const history = useHistory();

  const onClickCopiar = async (id) => {
    setFetchingCopy(true);
    const response = await copyEquipamentoCompartilhadoRepository.create({ id });
    if (!response.ok) {
      setMessage('Erro ao copiar equipamento!');
    } else {
      history.push(`/equipamentos/${response.data.id}`)
    }
    setFetchingCopy(false);
  }

  const downloadPDF = async (id) => {
    setDownloading(true);
    const response = await restApi.get(`equipamento-compartilhado-report/${id}`, {}, { responseType: `blob` })
    try {
      if (response.ok && response.data) {
        saveAs(response.data, `${entityManager.item?.nome || "NR-12 Equipamento"}.pdf`)
      } else {
        setMessage("Erro ao gerar relatório");
      }
    } catch (e) {
      setMessage("Erro ao gerar relatório");
    } finally {
      setDownloading(false);
    }
  }
  
  const ButtonCopiar = ({ id }) => {
    const classes = useStyles();
  
    return <Button variant="outlined" color="secondary" onClick={async () => onClickCopiar(id)} className={classes.button}>
      <Fetching size={24} isFetching={fetchingCopy}>{"COPIAR PARA MINHA EMPRESA"}</Fetching>
    </Button>
  }
  
  const ButtonReport = ({ id }) => {
    const classes = useStyles();
  
    return <Button variant="outlined" color="secondary" onClick={async () => downloadPDF(id)} className={classes.button}>
      <Fetching size={24} isFetching={downloading}>{"VISUALIZAR PDF"}</Fetching>
    </Button>
  }

  return <Paper className={classes.paper}>
    <Fetching isFetching={!item?.id}>
      <Grid container spacing={2} className={classes.container}>        
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} className={classes.fotoContainer} >
          <img
            className={classes.foto}
            src={item.foto || withoutLogo}
            alt="Foto do equipamento"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={9} xl={10}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ReadInput
                label="Identificação"
                value={item.nome}
              />
            </Grid>
            <Grid item xs={12}>
              <ReadInput
                label="Status"
                value={item.status}
              />
            </Grid>
            {(item.status === 'INATIVO' || item.status === 'EM_MANUTENCAO') && <Grid item xs={12}>
              <ReadInput
                label="Conclusão sobre equipamento inativo / em manutenção"
                value={item.observacaoInativo}
              />
            </Grid>}
            <Grid item xs={12} className={classes.obsFotoContainer}>
              <span className={classes.obsFoto}>{"*Foi retirada a visibilidade da foto do equipamento para preservar a privacidade da empresa."}</span>
            </Grid>
          </Grid>
        </Grid>     
      </Grid>
      <DadosCadastraisView item={item} />
      <DadosRiscoView item={item} />
    </Fetching>
    <div className={classes.buttonContainer}>
      <ButtonCopiar id={item.id} />
      <ButtonReport id={item.id} />
    </div>
    
    <Snackbar
      message={message}
      onClose={() => setMessage('')}
    /> 
  </Paper>
}

export default EquipamentoCompartilhadoView
