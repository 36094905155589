import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Field } from 'react-final-form'
import { TextField } from '../../Components/Fields'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import tiposEnergiaRepository from '../repositories/tiposEnergiaRepository'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import DeleteIcon from '@material-ui/icons/Delete'
import { Button, IconButton, makeStyles } from '@material-ui/core'

const schema = yup.object().shape({
  nome: yup.string().required('Obrigatório'),
})

const useStyles = makeStyles(theme => ({
  centerContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  removeIconLine: {
    display: 'flex',
  },
  removeIcon: {
    width: 20,
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: theme.spacing(1)
  },
  arrayTitle: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    fontSize: 18
  }
}))

const validate = yupValidation(schema)

const TipoEnergiaForm = props => {

  const entityManager = useEntityManager({
    repository: tiposEnergiaRepository,
    handleError: data => data.message,
    goBackAfterSave: true
  });

  const addTipoAplicacao = (form) => {
    form.mutators.push('tiposAplicacao', {
      op: 'add'
    })
  }

  const removeTipoAplicacao = (index, fields, form) => {
    if (fields.value[index].op === 'add') {
      form.mutators.remove('tiposAplicacao', index)
    } else {
      form.mutators.update('tiposAplicacao', index, { op: 'remove', ...fields.value[index] });
    }
  }

  const addModoAlimentacao = (form) => {
    form.mutators.push('modosAlimentacao', {
      op: 'add'
    })
  }

  const removeModoAlimentacao = (index, fields, form) => {
    if (fields.value[index].op === 'add') {
      form.mutators.remove('modosAlimentacao', index)
    } else {
      form.mutators.update('modosAlimentacao', index, { op: 'remove', ...fields.value[index] });
    }
  }

  const classes = useStyles();

  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        validate={validate}
        withPaper
        formProps={{
          mutators: arrayMutators
        }}
      >
        {({ form }) => <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="nome"
              label="Nome"
              component={TextField}
            />
          </Grid>
          <Grid item xs={12} className={classes.centerContainer}>
            <span className={classes.arrayTitle}>{"Tipos de Aplicação"}</span>
          </Grid>
          <FieldArray name="tiposAplicacao">
            {({ fields }) =>
              fields.map((name, index) => (       
                fields.value[index].op !== 'remove' && <Grid item xs={12} key={name} className={classes.removeIconLine}>
                  <Field
                  fullWidth
                  name={`${name}.descricao`}
                  label="Nome do Tipo de Aplicação"
                  component={TextField}
                />
                <div className={classes.removeIcon}>
                  <IconButton
                    size="small"
                    aria-label="Deletar"
                    onClick={() => removeTipoAplicacao(index, fields, form)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>  
              </Grid>))}
          </FieldArray>
          <Grid item xs={12} className={classes.centerContainer}>
            <Button onClick={() => addTipoAplicacao(form)} variant="outlined" color='secondary'>
              {"Adicionar Tipo de Aplicação"}
            </Button>
          </Grid>

          <Grid item xs={12} className={classes.centerContainer}>
            <span className={classes.arrayTitle}>{"Modos de Alimentação"}</span>
          </Grid>
          <FieldArray name="modosAlimentacao">
            {({ fields }) =>
              fields.map((name, index) => (       
                fields.value[index].op !== 'remove' && <Grid item xs={12} key={name} className={classes.removeIconLine}>
                  <Field
                  fullWidth
                  name={`${name}.descricao`}
                  label="Nome do Modo de Alimentação"
                  component={TextField}
                />
                <div className={classes.removeIcon}>
                  <IconButton
                    size="small"
                    aria-label="Deletar"
                    onClick={() => removeModoAlimentacao(index, fields, form)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>  
              </Grid>))}
          </FieldArray>
          <Grid item xs={12} className={classes.centerContainer}>
            <Button onClick={() => addModoAlimentacao(form)} variant="outlined" color='secondary'>
              {"Adicionar Modo de Alimentação"}
            </Button>
          </Grid>
                   
        </Grid>}
      </CrudForm>
    </>
  )
}

export default TipoEnergiaForm
