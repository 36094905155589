import { restApi } from '../../services/api'
import { createBasicRepository as createRepository } from '../../Components/Repository'
import { paginate } from '../../lib/feathers'

const conformidadesPerigoRepository = createRepository({
  path: 'conformidades-perigo',
  restApi,
  queryTransform: {
    ...paginate
  },
})

export default conformidadesPerigoRepository
