import moment from 'moment'
import React from 'react'
import { List } from '../../Components/Common'
import { useListRepository } from '../../Components/Hooks'
import bookSafetyRepository from '../repositories/bookSafetyRepository'

const BookSafetyList = ({ ...props }) => {
  const listOptions = {
    defaultOrder: 'createdAt desc',
    fields: {
      createdAt: {
        label: 'Data Geração',
        format: (data) => moment(data).format('DD/MM/YYYY HH:mm')
      },
      cliente: {
        label: 'Cliente',
        format: (cliente) => cliente.nome
      },
      status: {
        label: 'Status'
      },
      arquivoUrl: {
        label: 'Download',
        format: (url) => url ? <a href={url}>{"baixar"}</a> : ''
      }
    }
  }

  const listHook = useListRepository({
    repository: bookSafetyRepository,
    defaultSort: 'createdAt desc',
    path: 'book-safety'
  })
  
  return (
    <>
      <List
        {...props}
        {...listHook}
        removeItem={null}
        onClickEdit={null}
        onClickNew={null}
        onClickView={null}
        hideDownloadIcon
        listOptions={listOptions}
      />
    </>
  )
}

export default BookSafetyList
