import React, { Fragment, useState } from 'react'
import { Grid, makeStyles, Collapse, Table, TableHead, TableCell, TableBody, TableRow, TableContainer, Paper } from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import ReadInput from '../../Components/Common/ReadInput';
import PerigoEquipamentoView from './PerigoEquipamentoView';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3)
  },
  titleBox: {
    borderBottom: `solid 1px ${theme.palette.secondary.main}`
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(0.5)
  },
  tableGrid: {
    paddingTop: theme.spacing(2)
  },
  tableHead: {
    fontWeight: 'bold'
  },
  conformidadeContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(3)
  },
  descricaoPerigo: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main
  },
  clickable: {
    cursor: 'pointer'
  },
}))

const DadosRiscoView = ({ item }) => {

  const classes = useStyles();
  const [dadosRiscoIn, setDadosRiscoIn] = useState(true);
  const [perigosExpanded, setPerigosExpanded] = useState([])
    
  return <Grid container className={classes.container}>
    <Grid item xs={12} className={classes.titleBox}>
      <div onClick={() => setDadosRiscoIn(!dadosRiscoIn)}
        className={classes.title}
      >
        {`ANÁLISE DE RISCO ${dadosRiscoIn ? '<<' : '>>'}`}
      </div>
    </Grid>
    
    <Grid item xs={12} className={classes.tableGrid}>
      <Collapse in={dadosRiscoIn}>      
        {item.perigosEquipamento.length > 0 && <TableContainer component={Paper}>
          <Table >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead}>{"Perigo"}</TableCell>
                <TableCell className={classes.tableHead}>{"Classe Anterior"}</TableCell>
                <TableCell className={classes.tableHead}>{"Classe Após Melhoria"}</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {item.perigosEquipamento.map(pe => <Fragment key={pe.id}>
                <TableRow className={classes.clickable} onClick={() => {
                  if (perigosExpanded.includes(pe.id)) {
                    setPerigosExpanded(perigosExpanded.filter(pex => pex !== pe.id));
                  } else {
                    setPerigosExpanded(perigosExpanded.concat(pe.id));
                  }
                }}>              
                  <TableCell><span className={classes.descricaoPerigo}>{pe.perigo.descricao}</span></TableCell>
                  <TableCell>{pe.hrnAntes ? <span style={{ fontWeight: 'bold', color: pe.hrnAntes.corFundo }}>{pe.hrnAntes.nome}</span> : ''}</TableCell>
                  <TableCell>{pe.hrnDepois ? <span style={{ fontWeight: 'bold', color: pe.hrnDepois.corFundo }}>{pe.hrnDepois.nome}</span> : ''}</TableCell>
                  <TableCell>{perigosExpanded.includes(pe.id) ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}</TableCell>
                </TableRow>
                {perigosExpanded.includes(pe.id) && <TableRow>
                  <TableCell colSpan={4}>
                    <PerigoEquipamentoView item={pe}/>
                  </TableCell>
                </TableRow>}
              </Fragment>)}
            </TableBody>
          </Table>
        </TableContainer>}
        
        <Grid container className={classes.conformidadeContainer}>
          <Grid item xs={12}>
            <ReadInput
              value={item.conformidade}
              label="Equipamento está em conformdiade com a norma?"
            />
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
    
  </Grid>
}

export default DadosRiscoView;