import React from 'react'
import { Grid, makeStyles, Button, IconButton } from '@material-ui/core'
import { Field } from 'react-final-form'
import { TextField, Select } from '../../Components/Fields'
import { FieldArray } from 'react-final-form-arrays'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles(theme => ({
  addMaquinaContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  removeIconLine: {
    display: 'flex',
  },
  removeIcon: {
    width: 20,
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: theme.spacing(1)
  }
}))

const validaMaquina = value => (value ? undefined : "Obrigatório")

const DadosIdentificacao = ({ form }) => {

  const classes = useStyles();

  const addMaquina = () => {
    form.mutators.push('maquinas', {
      op: 'add'
    })
  }

  const removeMaquina = (index, fields) => {
    if (fields.value[index].op === 'add') {
      form.mutators.remove('maquinas', index)
    } else {
      form.mutators.update('maquinas', index, { op: 'remove', ...fields.value[index] });
    }
  }

  const modosEntradaSaida = [
    {
      label: "MANUAL",
      value: "MANUAL"
    },
    {
      label: "AUTOMÁTICO",
      value: "AUTOMATICO"
    },
    {
      label: "SEMI AUTOMÁTICO",
      value: "SEMI_AUTOMATICO"
    }
  ]
    
  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Field
        fullWidth
        name="atividade"
        label="Atividade do Equipamento"
        component={TextField}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        fullWidth
        name="localizacao"
        label="Localização/Setor"
        component={TextField}
      />
    </Grid>
    <Grid item xs={12} sm={8}>
      <Field
        fullWidth
        name="entrada"
        label="Entrada / Início"
        component={TextField}
      />
    </Grid>          
    <Grid item xs={12} sm={4}>
      <Field
        fullWidth
        name="modoEntrada"
        label="Modo Entrada"
        component={Select}
        options={modosEntradaSaida}
      />
    </Grid>
    <Grid item xs={12} sm={8}>
      <Field
        fullWidth
        name="saida"
        label="Saída / Término"
        component={TextField}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <Field
        fullWidth
        name="modoSaida"
        label="Modo Saída"
        component={Select}
        options={modosEntradaSaida}
      />
    </Grid>
    <FieldArray name="maquinas">
      {({ fields }) =>
        fields.map((name, index) => (       
          fields.value[index].op !== 'remove' && <Grid item xs={12} key={name}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Field
                  fullWidth
                  name={`${name}.nome`}
                  label="Máquina"
                  component={TextField}
                  validate={validaMaquina}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={classes.removeIconLine}>
                <Field
                  fullWidth
                  name={`${name}.tag`}
                  label="Tag"
                  component={TextField}
                />
                <div className={classes.removeIcon}>
                  <IconButton
                    size="small"
                    aria-label="Deletar"
                    onClick={() => removeMaquina(index, fields)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>  
              </Grid>
            </Grid>
          </Grid>
        ))
      }
    </FieldArray>
    <Grid item xs={12} className={classes.addMaquinaContainer}>
      <Button onClick={addMaquina} variant="outlined" color='secondary'>
        {"Adicionar Máquina/Tag"}
      </Button>
    </Grid>
  </Grid>
}

export default DadosIdentificacao;