import React from 'react'
import { Grid } from '@material-ui/core'
import ReadInput from '../../Components/Common/ReadInput';

const DadosEnergia = ({ item}) => {

  return <Grid container spacing={2}>
    <Grid item xs={12} sm={4}>
      <ReadInput
        value={item.paradaMovimentos ? 'SIM' : 'NÃO'}
        label="Parada de Movimentos"
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <ReadInput
        value={item.tipoCiclo}
        label="Tipo de Ciclo"
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <ReadInput
        value={item.intervaloCiclo}
        label="Intervalo do Ciclo (horas)"
      />
    </Grid>
    <Grid item xs={12}>
      <ReadInput
        value={item.historicoAcidentes}
        label="Histórico de Acidentes"
      />
    </Grid>
    
    <Grid item xs={12} sm={3}>
      <ReadInput
        value={item.treinamentoOperacional ? 'SIM' : 'NÃO'}
        label="Treinamento Operacional"
      />
    </Grid>
    <Grid item xs={12} sm={9}>
      <ReadInput
        value={item.obsTreinamentoOperacional}
        label="Obs. Treinamento Operacional"
      />
    </Grid>
    <Grid item xs={12} sm={3}>
      <ReadInput
        value={item.treinamentoSeguranca ? 'SIM' : 'NÃO'}
        label="Treinamento Segurança"
      />
    </Grid>
    <Grid item xs={12} sm={9}>
      <ReadInput
        value={item.obsTreinamentoSeguranca}
        label="Obs. Treinamento Segurança"
      />
    </Grid>
    <Grid item xs={12} sm={3}>
      <ReadInput
        value={item.treinamentoMantenedores ? 'SIM' : 'NÃO'}
        label="Treinamento Mantenedores"
      />
    </Grid>
    <Grid item xs={12} sm={9}>
      <ReadInput
        value={item.obsTreinamentoMantenedores}
        label="Obs. Treinamento Mantenedores"
      />
    </Grid>
    <Grid item xs={12} sm={3}>
      <ReadInput
        value={item.manualMaquina ? 'SIM' : 'NÃO'}
        label="Manual da Máquina"
      />
    </Grid>
    <Grid item xs={12} sm={9}>
      <ReadInput
        value={item.obsManualMaquina}
        label="Obs. Manual da Máquina"
      />
    </Grid>
    <Grid item xs={12} sm={3}>
      <ReadInput
        value={item.procedimento ? 'SIM' : 'NÃO'}
        label="Procedimento"
      />
    </Grid>
    <Grid item xs={12} sm={9}>
      <ReadInput
        value={item.obsProcedimento}
        label="Obs. Procedimento"
      />
    </Grid>    
  </Grid>
}

export default DadosEnergia;