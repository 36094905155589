import React from 'react'
import {Login} from '../../Components/Login'
import api from '../../services/api'
import * as R from 'ramda'
import logo from '../../img/logo.png'

export const handleLogin = ({login, setToken=R.empty}) => async ({username: email, password}) => {
  if (!email || !password) return
  const response = await login({
    email,
    password
  })
  if (response.ok) {
    if (!response.data.user) {
      return {ok: false}
    }
    if (!response.data.accessToken) {
      return {ok: false, message: 'Token não encontrado'}
    }
    setToken(response.data.accessToken)
    return {
      ok: true,
      token: response.data.accessToken,
      id: response.data.user.id,
      email: response.data.user.email,
      name: response.data.user.name,
      role: response.data.user.role,
      compartilhamento: response.data.user.empresa.compartilhamento
    }
  }
  return {ok: false}
}
const LoginForm = ({history}) => {


  return (
    <Login
      logo={
        <img
          alt='logo'
          src={logo}
          style={{ width: 300 }} />
      }
      history={history}
      onSubmit={handleLogin(api)}
      requiredLabel='Obrigatório'
      usernameLabel='Usuário'
      submitLabel='Entrar'
      passwordLabel='Senha'
      recoverPasswordLabel='Recuperar Senha'
      />
  )
}

export default LoginForm
