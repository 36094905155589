import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({  
  disabled: {
    color: theme.palette.primary.main
  }
}));

export default ({label, value}) => {
  const classes = useStyles();

  return <TextField
    fullWidth                
    InputProps={{
      classes:{
        disabled: classes.disabled
      }
    }}
    label={label}
    value={value || ""}
    disabled
  />
  
};