import React, { useContext, useState } from 'react'
import { Field } from 'react-final-form';
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import { Form } from 'react-final-form'
import api from '../../services/api';
import { useHistory } from 'react-router';
import Snackbar from '../../Components/Common/Snackbar';
import { TextField } from '../../Components/Fields';
import { AuthContext } from '../../Components/Login';
import logo from '../../img/logo.png';

const emailValido = (email) => {
  const regex = /\S+@\S+\.\S+/;
  return regex.test(email);
}

const useStyles = makeStyles(theme => ({
  background: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: theme.palette.primary.main
  },
  paper: {
    padding: theme.spacing(2),
    width: 270,
    display: 'flex',
    justifyContent: 'center'
  },
  loginText: {
    color: theme.palette.white
  },
  title: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  info: {
    color: theme.palette.secondary.main,
    fontSize: 12,
    textAlign: 'center',
    marginTop: theme.spacing(1)
  },
  logo: {
    width: 250,
    marginBottom: theme.spacing(2)
  },
  buttonContainer: {
    marginTop: theme.spacing(2)
  }
}));

export default () => {

  const [fetching, setFetching] = useState(false);
  const [erro, setErro] = useState();
  const classes = useStyles();
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const validate = values => ({
    nomeEmpresa: !values.nomeEmpresa ? "Obrigatório" : undefined,
    email: !values.email ? "Campo Obrigatório" : !emailValido(values.email) ? 'Formato inválido' : undefined,
    password: !values.password 
      ? "Obrigatório" 
      : values.password.length < 6
        ? "A senha dever pelo menos 6 caracteres"
        : undefined,
    password_confirmation: values.password !== values.password_confirmation ? "Senhas não conferem" : undefined
  });

  const handleCreate = (values) => {
    setFetching(true);
    api.register(values).then(response => {
      if (response.ok) {
        if (!response.data.accessToken) {
          setErro("Token Expirado")
        } else {
          authContext.handleUserLogin({
            ok: true,
            token: response.data.accessToken,
            id: response.data.user.id,
            email: response.data.user.email,
            name: response.data.user.name,
            role: response.data.user.role,
            compartilhamento: false
          });

          history.push('/');
        }
      } else {
        setErro(`Erro no registro: ${response.data.message}`)
      }

      setFetching(false);
    })
  } 


  return (
    <div className={classes.background}>
      <Paper
        className={classes.paper}
        elevation={4}>
        <Form onSubmit={handleCreate} validate={validate}>
          {
            ({handleSubmit}) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <img
                      alt='logo'
                      src={logo}
                      className={classes.logo} />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      label="Nome da empresa"
                      name='nomeEmpresa'/>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      label="E-Mail"
                      name='email'/>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      label="Senha"
                      type='password'
                      name='password'/>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      label="Confirme a Senha"
                      type='password'
                      name='password_confirmation'/>
                  </Grid>
                  <Grid item xs={12} className={classes.buttonContainer}>
                    <Button
                      fullWidth
                      disabled={fetching}
                      variant='contained'
                      color='primary'
                      type='submit'>
                        <span className={classes.loginText}>{"CADASTRAR"}</span>
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant='text'
                      color='secondary'
                      onClick={() => history.goBack()}
                    >
                      {"Voltar"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )
          }
        </Form>
      </Paper>
      <Snackbar
        onClose={() => setErro(undefined)}
        message={erro} />
    </div>
  )
}
