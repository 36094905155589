import React, { useEffect, useState } from 'react'
import { Button, Grid, IconButton, makeStyles } from '@material-ui/core';
import materiaisRepository from '../repositories/materiaisRepository';
import { Field } from 'react-final-form';
import { AutocompleteCreatable, TextField } from '../../Components/Fields';
import { FieldArray } from 'react-final-form-arrays';
import DeleteIcon from '@material-ui/icons/Delete';
import MoneyField from '../../Components/Fields/MoneyField';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  removeIconLine: {
    display: 'flex',
  },
  removeIcon: {
    width: 20,
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: theme.spacing(1)
  },
  addContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
}))

const MateriaisPlanoAcao = ({ form }) => {

  const [materiais, setMateriais] = useState([]);

  useEffect(() => {
    ;(async () => {
      const response = await materiaisRepository.list({
        paginate: false,
        query: {
          $limit: 999
        }
      });

      if (response.ok) {
        setMateriais(response.data.map(mat => ({
          label: mat.descricao,
          value: mat.id,
          ultimoPreco: mat.ultimoPreco
        })))
      }
    })()

  }, []);

  const loadOptions = (query) => {
    return Promise.resolve(materiais
      .filter(m => m.label.toUpperCase().includes(query.toUpperCase())))
  }

  const classes = useStyles();

  const addMaterial = () => {
    form.mutators.push('materiais', {
      op: 'add'
    })
  }

  const removeMaterial = (index, fields) => {
    if (fields.value[index].op === 'add') {
      form.mutators.remove('materiais', index)
    } else {
      form.mutators.update('materiais', index, { ...fields.value[index],  op: 'remove' });
    }
  }

  const setPreco = (item, index, fields) => {
    form.mutators.update('materiais', index, { ...fields.value[index], valorUnitario: item.ultimoPreco });
  }

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <span className={classes.label}>{"Orçamento - Materiais Necessários"}</span>
    </Grid>
    <FieldArray name="materiais">
      {({ fields }) =>
        fields.map((name, index) => (       
          fields.value[index].op !== 'remove' && <Grid item xs={12} key={name}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  fullWidth
                  name={`${name}.material`}
                  label=" "
                  component={AutocompleteCreatable}
                  loadOptions={loadOptions}
                  beforeOnChange={(item) => setPreco(item, index, fields)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Field
                  fullWidth
                  name={`${name}.quantidade`}
                  label="Quantidade"
                  component={TextField}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={3} className={classes.removeIconLine}>
                <Field
                  fullWidth
                  name={`${name}.valorUnitario`}
                  label="Valor Unitario"
                  component={MoneyField}
                  type="number"
                />
                <div className={classes.removeIcon}>
                  <IconButton
                    size="small"
                    aria-label="Deletar"
                    onClick={() => removeMaterial(index, fields)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>  
              </Grid>
            </Grid>
          </Grid>))}
    </FieldArray>    
    <Grid item xs={12} className={classes.addContainer}>
      <Button onClick={addMaterial} variant="outlined" color='secondary'>
        {"Adicionar Material"}
      </Button>
    </Grid>
  </Grid>
}

export default MateriaisPlanoAcao;