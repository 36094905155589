import { restApi } from '../../services/api'
import { createBasicRepository as createRepository } from '../../Components/Repository'
import { paginate } from '../../lib/feathers'

const clientesRepository = createRepository({
  path: 'tipos-energia',
  restApi,
  queryTransform: {
    ...paginate
  },
  dataTransform: (data) => {
    for (const tipo of data.tiposAplicacao) {
      if (tipo.id && !tipo.op) {
        tipo.op = 'replace'
      }
    }
    for (const modo of data.modosAlimentacao) {
      if (modo.id && !modo.op) {
        modo.op = 'replace'
      }
    }

    return data
  }
})

export default clientesRepository
