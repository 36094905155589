import React from 'react'
import { Grid } from '@material-ui/core'
import ReadInput from '../../Components/Common/ReadInput'


const DadosSeguranca = ({ item }) => {
  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <ReadInput
        label="Proteções Fixas"
        value={item.protecoesFixas.map(ds => ds.label).join('; ')}
      />
    </Grid>
    <Grid item xs={12}>
      <ReadInput
        label="Proteções Móveis"
        value={item.protecoesMoveis.map(ds => ds.label).join('; ')}
      />
    </Grid>
    <Grid item xs={12}>
      <ReadInput
        label="Dispositivos de Segurança"
        value={item.dispositivosSeguranca.map(ds => ds.label).join('; ')}
      />
    </Grid>
    <Grid item xs={12}>
      <ReadInput
        label="Outros"
        value={item.outrosDispositivosSeguranca.map(ds => ds.label).join('; ')}
      />
    </Grid>
    <Grid item xs={12}>
      <ReadInput
        label="Observações"
        value={item.observacoes}
      />
    </Grid>
  </Grid>
}

export default DadosSeguranca;