import React, { Fragment, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import ReadInput from '../../Components/Common/ReadInput'
import { formatCategoriaSeguranca } from '../perigosEquipamento/PerigoEquipamentoForm'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import PlanoAcaoView from './PlanoAcaoView'
import ConformidadeView from './ConformidadeView'

const useStyles = makeStyles(theme => ({
  classeHrn: {
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  categoriaSegurancaContainer: {
    backgroundColor: theme.palette.grey[200],
  },
  categoriaSeguranca: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  tableGrid: {
    paddingTop: theme.spacing(2)
  },
  tableHead: {
    fontWeight: 'bold'
  },
  descricaoPlanoAcao: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main
  },
  cellDescricao: {
    width: '100%'
  },
  clickable: {
    cursor: 'pointer',
  },
  container: {
    padding: theme.spacing(2)
  },
  conformidadesTable: {
    marginTop: theme.spacing(1)
  },
}));

const PerigoEquipamentoView = ({ item }) => {

  const classes = useStyles();
  const [planosAcaoExtended, setPlanosAcaoExtended] = useState([]);
  const [conformidadesExtended, setConformidadesExtended] = useState([]);

  return <Grid container spacing={2} className={classes.container}>
    <Grid item xs={12} sm={6} md={3} >
      <ReadInput
        value={item.npAntes ? `(${item.npAntes.fator}) ${item.npAntes.descricao}` : ''}
        label="Quantidade Pessoas (Antes)"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3} >
      <ReadInput
        value={item.dphAntes ? `(${item.dphAntes.fator}) ${item.dphAntes.descricao}` : ''}
        label="Grau Possível Lesão (Antes)"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3} >
      <ReadInput
        value={item.feAntes ? `(${item.feAntes.fator}) ${item.feAntes.descricao}` : ''}
        label="Frequência Exposição (Antes)"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3} >
      <ReadInput
        value={item.loAntes ? `(${item.loAntes.fator}) ${item.loAntes.descricao}` : ''}
        label="Probabilidade Ocorrência (Antes)"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3} >
      <ReadInput
        value={item.npDepois ? `(${item.npDepois.fator}) ${item.npDepois.descricao}` : ''}
        label="Quantidade Pessoas (Depois)"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3} >
      <ReadInput
        value={item.dphDepois ? `(${item.dphDepois.fator}) ${item.dphDepois.descricao}` : ''}
        label="Grau Possível Lesão (Depois)"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3} >
      <ReadInput
        value={item.feDepois ? `(${item.feDepois.fator}) ${item.feDepois.descricao}` : ''}
        label="Frequência Exposição (Depois)"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3} >
      <ReadInput
        value={item.loDepois ? `(${item.loDepois.fator}) ${item.loDepois.descricao}` : ''}
        label="Probabilidade Ocorrência (Depois)"
      />
    </Grid>

    {item.hrnAntes && item.hrnDepois && <>
      <Grid item xs={12} sm={6}>
        <div style={{ 
          backgroundColor: item.hrnAntes.corFundo, 
          color: item.hrnAntes.corTexto,
          width: '100%',
        }}>
          <div className={classes.classeHrn}>{`HRN Antes: ${item.valorHrnAntes} - ${item.hrnAntes.nome}`}</div>
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div style={{ 
          backgroundColor: item.hrnDepois.corFundo, 
          color: item.hrnDepois.corTexto,
          width: '100%'
        }}>
          <div className={classes.classeHrn}>{`HRN Depois: ${item.valorHrnDepois} - ${item.hrnDepois.nome}`}</div>
        </div>
      </Grid>
    </>}

    <Grid item xs={12} sm={6} md={3} >
      <ReadInput
        value={item.possibilidadeEvitar}
        label="Possibilidade de Evitar"
      />
    </Grid>
    <Grid item xs={12} sm={6} md={9} >
      <ReadInput
        value={item.observacao}
        label="Observações"
      />
    </Grid>
    
    {item.categoriaSeguranca && <Grid item xs={12}>
      <div className={classes.categoriaSegurancaContainer}>
        <div className={classes.categoriaSeguranca}>
          {`Categorias de Segurança: [ ${formatCategoriaSeguranca(item.categoriaSeguranca)} ]`}
        </div>
      </div>
    </Grid>}

    <Grid item xs={12} className={classes.tableGrid}>
      {item.planosAcao.length > 0 && <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>{"Planos de Ação"}</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {item.planosAcao.map(pa => <Fragment key={pa.id}>
              <TableRow className={classes.clickable} onClick={() => {
                if (planosAcaoExtended.includes(pa.id)) {
                  setPlanosAcaoExtended(planosAcaoExtended.filter(pax => pax !== pa.id));
                } else {
                  setPlanosAcaoExtended(planosAcaoExtended.concat(pa.id));
                }
              }}>              
                <TableCell className={classes.cellDescricao}><span className={classes.descricaoPlanoAcao}>{pa.itensPlanoAcao.map(ipa => ipa.planoAcao.descricao).join('; ')}</span></TableCell>
                <TableCell>{planosAcaoExtended.includes(pa.id) ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}</TableCell>
              </TableRow>
              {planosAcaoExtended.includes(pa.id) && <TableRow>
                <TableCell colSpan={2}>
                  <PlanoAcaoView item={pa}/>
                </TableCell>
              </TableRow>}
            </Fragment>)}
          </TableBody>
        </Table>
      </TableContainer>}

      {item.conformidades.length > 0 && <TableContainer component={Paper} className={classes.conformidadesTable}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>{"Conformidades"}</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {item.conformidades.map(cp => <Fragment key={cp.id}>
              <TableRow className={classes.clickable} onClick={() => {
                if (conformidadesExtended.includes(cp.id)) {
                  setConformidadesExtended(conformidadesExtended.filter(cpx => cpx !== cp.id));
                } else {
                  setConformidadesExtended(conformidadesExtended.concat(cp.id));
                }
              }}>              
                <TableCell className={classes.cellDescricao}><span className={classes.descricaoPlanoAcao}>{cp.conformidade.descricao}</span></TableCell>
                <TableCell>{conformidadesExtended.includes(cp.id) ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}</TableCell>
              </TableRow>
              {conformidadesExtended.includes(cp.id) && <TableRow>
                <TableCell colSpan={2}>
                  <ConformidadeView item={cp}/>
                </TableCell>
              </TableRow>}
            </Fragment>)}
          </TableBody>
        </Table>
      </TableContainer>}

    </Grid>

  </Grid>
}

export default PerigoEquipamentoView
