import { restApi } from '../../services/api'
import { createBasicRepository as createRepository } from '../../Components/Repository'

const itensNormaRepository = createRepository({
  path: 'itens-norma',
  restApi,  
  listResponsePath: {
    pathData: ['data'],
    pathCount: ['total'],
  },
})

export default itensNormaRepository
