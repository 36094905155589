import { restApi } from '../../services/api'
import { createBasicRepository as createRepository } from '../../Components/Repository'
import { paginate, query } from '../../lib/feathers'

const dispositivosSegurancaRepository = createRepository({
  path: 'dispositivos-seguranca',
  restApi, 
  queryTransform: {
    descricao: query.ilike('descricao'),
    ...paginate
  },
})

export default dispositivosSegurancaRepository
