import { Grid, makeStyles, Paper } from '@material-ui/core'
import { Business, SettingsApplications, MenuBook, Settings } from '@material-ui/icons'
import React from 'react'
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  containerUp: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(3),
    width: '100%',
    height: '50%'
  },
  containerDown: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(3),
    width: '100%',
    height: '50%'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 250,
    height: 250,
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    cursor: 'pointer'
  },
  icon: {
    fontSize: 120,
    color: theme.palette.secondary.main
  },
  text: {
    paddingTop: theme.spacing(3),
    fontSize: 20,
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  }
}))

export default function Home() {

  const classes = useStyles();
  const history = useHistory();

  return <>
    <Grid container className={classes.containerUp}>
      <Grid item>
        <Paper className={classes.paper} onClick={() => history.push('/clientes')}>
          <Business className={classes.icon} />
          <span className={classes.text}>{"CLIENTES"}</span>
        </Paper>
      </Grid>
      <Grid item>
        <Paper className={classes.paper} onClick={() => history.push('/equipamentos')}>
          <SettingsApplications className={classes.icon} />
          <span className={classes.text}>{"EQUIPAMENTOS"}</span>
        </Paper>
      </Grid>
    </Grid>
    <Grid container className={classes.containerDown}>
      <Grid item>
        <Paper className={classes.paper} onClick={() => history.push('/book-safety')}>
          <MenuBook className={classes.icon} />
          <span className={classes.text}>{"BOOK SAFETY"}</span>
        </Paper>
      </Grid>
      <Grid item>
        <Paper className={classes.paper} onClick={() => history.push('/configuracoes')}>
          <Settings className={classes.icon} />
          <span className={classes.text}>{"CONFIGURAÇÕES"}</span>
        </Paper>
      </Grid>
    </Grid>
  </>
}
