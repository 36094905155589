import React from 'react'
import { Grid } from '@material-ui/core'
import ReadInput from '../../Components/Common/ReadInput'

const DadosFabricante = ({ item }) => <Grid container spacing={2}>
  <Grid item xs={12} sm={4}>
    <ReadInput
      value={item.cnpjFabricante}
      label="CNPJ do Fabricante"
    />
  </Grid>
  <Grid item xs={12} sm={4}>
    <ReadInput
      value={item.nomeFabricante}
      label="Nome do Fabricante"
    />
  </Grid>
  <Grid item xs={12} sm={4}>
    <ReadInput
      value={item.contatoFabricante}
      label="Contato do Fabricante"
    />
  </Grid>
  <Grid item xs={12}>
    <ReadInput
      value={item.enderecoFabricante}
      label="Endereço do Fabricante"
    />
  </Grid>
  <Grid item xs={12} sm={4}>
    <ReadInput
      value={item.anoFabricacao}
      label="Ano de Fabricação"
    />
  </Grid>
  <Grid item xs={12} sm={4}>
    <ReadInput
      value={item.serie}
      label="Série"
    />
  </Grid>
  <Grid item xs={12} sm={4}>
    <ReadInput
      value={item.modelo}
      label="Modelo"
    />
  </Grid>
  <Grid item xs={12} sm={6}>
    <ReadInput
      value={item.potencia}
      label="Potência"
    />
  </Grid>
  <Grid item xs={12} sm={6}>
    <ReadInput
      value={item.capacidade}
      label="Capacidade"
    />
  </Grid>
</Grid>

export default DadosFabricante;